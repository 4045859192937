import React, { useEffect, useState } from 'react';
import './footerBar.css';
import api from '../../services/api';
import { vhostV1 } from '../funcoes';

function FooterBar() {

    const [empresa, setEmpresa] = useState([]);
    const [sResult, setSresult] = useState(0);
    const loja = localStorage.getItem('loja');
    

    let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }

    useEffect(function(){
    
        api.post(vhostV1('webcash/empresa/lista'), {
            loja: localStorage.getItem('loja')
            
          },config)
          .then(function (response) {       
            console.log(response);
                
            setEmpresa(response.data.dados); 
            setSresult(response.data.result)
            
                                        
                     
          })
          .catch(function (error) {
           
            console.log(error);
  
                    
          });
    
          
        },[])

        const razaoSocial = empresa[0]?.RAZAO_SOCIAL;

   
    

  return (
    <footer className="footer text-center">
      <div className="container-fluid">
        <p id='pFooter'>© 2024, {razaoSocial}, Loja {loja}. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default FooterBar;