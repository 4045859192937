import React, { useEffect, useState } from "react";
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import Navbar from "../navbar/navbar";
import FooterBar from "../footer/footerBar";
import customizeScrollbar from "customsb";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

function DreDashboard() {



  useEffect(() => {
    
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

  let config = {
    headers: {
       'Authorization': localStorage.getItem("token")
       }
    }

  // useEffect(() => {
  //   api.post(vhostV1('Dre/TotaisVendaDiasMes'),{
  //     empresa: 0,
  //     ano: "2024",
  //     mes: "2"
  //   },config)
  // },[])

  const [tipoRelatorio, setTipoRelatorio] = useState('M'); 
  const [mesSelecionado, setMesSelecionado] = useState('Novembro');
  const [anoSelecionado, setAnoSelecionado] = useState('2024');

  
  const gerarValorAleatorio = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  
  const gerarValoresContasReceber = (diasOuMeses) => {
    return Array(diasOuMeses).fill(0).map(() => gerarValorAleatorio(1000, 5000));  
  };
  
  const [dadosMensaisOperacoes, setDadosMensaisOperacoes] = useState([
    { mes: 'Janeiro', ano: '2024', operacoes: gerarValoresContasReceber(31) },
    { mes: 'Fevereiro', ano: '2024', operacoes: gerarValoresContasReceber(28) },
    { mes: 'Março', ano: '2024', operacoes: gerarValoresContasReceber(31) },
    { mes: 'Novembro', ano: '2024', operacoes: gerarValoresContasReceber(30) },
  ]);
  
  const [dadosAnuaisOperacoes, setDadosAnuaisOperacoes] = useState([
    { ano: '2024', operacoes: gerarValoresContasReceber(12) },
  ]);

  const gerarValoresFinalizadoras = (diasOuMeses) => {
    return Array(diasOuMeses).fill(0).map(() => ({
      dinheiro: gerarValorAleatorio(1000, 5000),
      promissorias: gerarValorAleatorio(500, 2000),
      cartaoCredito: gerarValorAleatorio(2000, 6000),
      cartaoDebito: gerarValorAleatorio(500, 3000),
    }));
  };

  const [dadosMensaisFinalizadoras, setDadosMensaisFinalizadoras] = useState([
    { mes: 'Janeiro', ano: '2024', finalizadoras: gerarValoresFinalizadoras(31) },
    { mes: 'Fevereiro', ano: '2024', finalizadoras: gerarValoresFinalizadoras(28) },
    { mes: 'Março', ano: '2024', finalizadoras: gerarValoresFinalizadoras(31) },
    { mes: 'Novembro', ano: '2024', finalizadoras: gerarValoresFinalizadoras(30) },
  ]);
  
  const [dadosAnuaisFinalizadoras, setDadosAnuaisFinalizadoras] = useState([
    { ano: '2024', finalizadoras: gerarValoresFinalizadoras(12) },
  ]);

  const gerarValorPromissorias = (diasOuMeses) => {
    return Array(diasOuMeses).fill(0).map(() => gerarValorAleatorio(1000, 5000));
  };
  
  const [dadosMensaisPromissorias, setDadosMensaisPromissorias] = useState([
    { mes: 'Janeiro', ano: '2024', promissorias: gerarValorPromissorias(31) },
    { mes: 'Fevereiro', ano: '2024', promissorias: gerarValorPromissorias(28) },
    { mes: 'Março', ano: '2024', promissorias: gerarValorPromissorias(31) },
    { mes: 'Novembro', ano: '2024', promissorias: gerarValorPromissorias(30) },
  ]);

  const [dadosAnuaisPromissorias, setDadosAnuaisPromissorias] = useState([
    {ano: '2024', promissorias: gerarValorPromissorias(12)}
  ]);

  const calcularTotaisPromissorias = () => {
    const promissorias = tipoRelatorio === 'M'
      ? dadosMensaisPromissorias.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.promissorias || []
      : dadosAnuaisPromissorias.find((dado) => dado.ano === anoSelecionado)?.promissorias || [];

    const totalPromissorias = promissorias.reduce((total, valor) => total + valor, 0);
    const totalPromissoriasPago = totalPromissorias * 0.6; 
    const totalPromissoriasPendente = totalPromissorias * 0.4;  

    return { totalPromissorias, totalPromissoriasPago, totalPromissoriasPendente };
  };

  
  const handleTipoRelatorioChange = (event) => {
    setTipoRelatorio(event.target.value);
  };

  
  const handleMesChange = (event) => {
    setMesSelecionado(event.target.value);
  };

  const handleAnoChange = (event) => {
    setAnoSelecionado(event.target.value);
  };


  const calcularTotaisOperacoes = () => {
    const operacoes = tipoRelatorio === 'M'
      ? dadosMensaisOperacoes.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.operacoes || []
      : dadosAnuaisOperacoes.find((dado) => dado.ano === anoSelecionado)?.operacoes || [];

    const totalOperacoes = operacoes.reduce((total, valor) => total + valor, 0);
    const totalPago = totalOperacoes * 0.6; 
    const totalPendente = totalOperacoes * 0.4;  

    return { totalOperacoes, totalPago, totalPendente };
  };

  
  const calcularTotaisVendas = () => {
    const totalVendas = gerarValorAleatorio(15000, 30000);
    const vendasDia = gerarValorAleatorio(1000, 3000);
    const ticketMedio = totalVendas / 30; 
    return { totalVendas, vendasDia, ticketMedio };
  };

  const { totalOperacoes, totalPago, totalPendente } = calcularTotaisOperacoes();
  const { totalVendas, vendasDia, ticketMedio } = calcularTotaisVendas();
  const { totalPromissorias, totalPromissoriasPago, totalPromissoriasPendente} = calcularTotaisPromissorias();

  const dadosGraficoOperacoes = tipoRelatorio === 'M'
    ? {
        labels: Array.from({ length: new Date(anoSelecionado, ['Janeiro', 'Fevereiro', 'Março', 'Novembro'].indexOf(mesSelecionado) + 1, 0).getDate() }, (_, i) => `Dia ${i + 1}`),
        datasets: [
          {
            label: 'Total do dia',
            backgroundColor: '#FFD700',
            data: dadosMensaisOperacoes.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.operacoes || [],
          },
        ],
      }
    : {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Total do Mês',
            backgroundColor: '#FFD700',
            data: dadosAnuaisOperacoes.find((dado) => dado.ano === anoSelecionado)?.operacoes || [],
          },
        ],
      };

      const calcularTotalVisivel = (chart) => {
        // Filtra os datasets visíveis
        const datasetsVisiveis = chart.data.datasets.filter((dataset, index) => {
          const meta = chart.getDatasetMeta(index);
          return meta && meta.visible; // Verifica se o dataset está visível
        });
      
        // Calcula o total considerando os datasets visíveis
        const totaisDiariosOuAnuais = chart.data.labels.map((_, dataIndex) => {
          return datasetsVisiveis.reduce((total, dataset) => {
            return total + (dataset.data[dataIndex] || 0); // Soma os valores do dataset visível
          }, 0);
        });
      
        return totaisDiariosOuAnuais;
      };

      const dadosGraficoFinalizadoras = tipoRelatorio === 'M'
      ? {
          labels: Array.from({ length: new Date(anoSelecionado, ['Janeiro', 'Fevereiro', 'Março', 'Novembro'].indexOf(mesSelecionado) + 1, 0).getDate() }, (_, i) => `Dia ${i + 1}`),
          datasets: [
            {
              label: 'Dinheiro',
              backgroundColor: '#FFD700',
              data: dadosMensaisFinalizadoras.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.dinheiro) || [],
            },
            {
              label: 'Promissórias',
              backgroundColor: '#87CEEB',
              data: dadosMensaisFinalizadoras.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.promissorias) || [],
            },
            {
              label: 'Cartão de Crédito',
              backgroundColor: '#FF6347',
              data: dadosMensaisFinalizadoras.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.cartaoCredito) || [],
            },
            {
              label: 'Cartão de Débito',
              backgroundColor: '#32CD32',
              data: dadosMensaisFinalizadoras.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.cartaoDebito) || [],
            },
            
          ],
        }
      : {
          labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          datasets: [
            {
              label: 'Dinheiro',
              backgroundColor: '#FFD700',
              data: dadosAnuaisFinalizadoras.find((dado) => dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.dinheiro) || [],
            },
            {
              label: 'Promissórias',
              backgroundColor: '#87CEEB',
              data: dadosAnuaisFinalizadoras.find((dado) => dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.promissorias) || [],
            },
            {
              label: 'Cartão de Crédito',
              backgroundColor: '#FF6347',
              data: dadosAnuaisFinalizadoras.find((dado) => dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.cartaoCredito) || [],
            },
            {
              label: 'Cartão de Débito',
              backgroundColor: '#32CD32',
              data: dadosAnuaisFinalizadoras.find((dado) => dado.ano === anoSelecionado)?.finalizadoras.map((f) => f.cartaoDebito) || [],
            },
            
          ],
        };
  

      const dadosGraficoPromissorias = tipoRelatorio === 'M'
      ? {
          labels: Array.from({ length: new Date(anoSelecionado, ['Janeiro', 'Fevereiro', 'Março', 'Novembro'].indexOf(mesSelecionado) + 1, 0).getDate() }, (_, i) => `Dia ${i + 1}`),
          datasets: [
            {
              label: 'Promissórias Pagas',
              backgroundColor: '#FFD700',
              data: dadosMensaisPromissorias.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.promissorias.map((valor) => valor * 0.6) || [], // 60% são pagas
              borderColor: '#FFD700',
              borderWidth: 1,
              fill: true,
            },
            {
              label: 'Promissórias Pendentes',
              backgroundColor: '#87CEEB',
              data: dadosMensaisPromissorias.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.promissorias.map((valor) => valor * 0.4) || [], // 40% são pendentes
              borderColor: '#87CEEB',
              borderWidth: 1,
              fill: true,
            },
          ],
          total: {
            pagas: dadosMensaisPromissorias.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.promissorias.reduce((total, valor) => total + valor * 0.6, 0) || 0,
            pendentes: dadosMensaisPromissorias.find((dado) => dado.mes === mesSelecionado && dado.ano === anoSelecionado)?.promissorias.reduce((total, valor) => total + valor * 0.4, 0) || 0,
          }
        }
      : {
          labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          datasets: [
            {
              label: 'Promissórias Pagas',
              backgroundColor: '#FFD700',
              data: dadosAnuaisPromissorias.find((dado) => dado.ano === anoSelecionado)?.promissorias.map((valor) => valor * 0.6) || [], // 60% são pagas
              borderColor: '#FFD700',
              borderWidth: 1,
              fill: true,
            },
            {
              label: 'Promissórias Pendentes',
              backgroundColor: '#87CEEB',
              data: dadosAnuaisPromissorias.find((dado) => dado.ano === anoSelecionado)?.promissorias.map((valor) => valor * 0.4) || [], // 40% são pendentes
              borderColor: '#87CEEB',
              borderWidth: 1,
              fill: true,
            },
          ],
          total: {
            pagas: dadosAnuaisPromissorias.find((dado) => dado.ano === anoSelecionado)?.promissorias.reduce((total, valor) => total + valor * 0.6, 0) || 0,
            pendentes: dadosAnuaisPromissorias.find((dado) => dado.ano === anoSelecionado)?.promissorias.reduce((total, valor) => total + valor * 0.4, 0) || 0,
          }
        };
    

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 8,
      },
    },
    plugins: {
      tooltip: {
        bodyFont: {
          size: 18,
        },
        titleFont: {
          size: 20,
        },
        padding: 10,
        boxPadding: 5,
      },
    },
  };

  const optionsFinalizadoras = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: {
        mode: 'index', 
        intersect: false,
        callbacks: {
          label: (context) => {
            const datasetIndex = context.datasetIndex;
            const dataIndex = context.dataIndex;
    
            const dinheiro = context.chart.data.datasets[0].data[dataIndex];
            const promissorias = context.chart.data.datasets[1].data[dataIndex];
            const cartaoCredito = context.chart.data.datasets[2].data[dataIndex];
            const cartaoDebito = context.chart.data.datasets[3].data[dataIndex];
    
            const totalDia = dinheiro + promissorias + cartaoCredito + cartaoDebito;
    
            // Exibe o valor de cada categoria
            if (datasetIndex === 0) {
              return `Dinheiro: R$ ${dinheiro.toFixed(2).replace('.', ',')}`;
            } else if (datasetIndex === 1) {
              return `Promissórias: R$ ${promissorias.toFixed(2).replace('.', ',')}`;
            } else if (datasetIndex === 2) {
              return `Cartão de Crédito: R$ ${cartaoCredito.toFixed(2).replace('.', ',')}`;
            } else if (datasetIndex === 3) {
              return `Cartão de Débito: R$ ${cartaoDebito.toFixed(2).replace('.', ',')}`;
            }
          },
          footer: (tooltipItems) => {
            // Verifica se o tooltipItems contém dados válidos
            const dinheiro = tooltipItems[0] ? tooltipItems[0].parsed.y : 0;
            const promissorias = tooltipItems[1] ? tooltipItems[1].parsed.y : 0;
            const cartaoCredito = tooltipItems[2] ? tooltipItems[2].parsed.y : 0;
            const cartaoDebito = tooltipItems[3] ? tooltipItems[3].parsed.y : 0;
    
            const totalDia = dinheiro + promissorias + cartaoCredito + cartaoDebito;
    
            return `Total: R$ ${totalDia.toFixed(2).replace('.', ',')}`;
          }
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
};
;

  const optionsGraficoPromissorias = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: {
        mode: 'index', 
        intersect: false,
        callbacks: {
          label: (context) => {
            const datasetIndex = context.datasetIndex;
            const dataIndex = context.dataIndex;
    
            const promissoriasPagas = context.chart.data.datasets[0].data[dataIndex];
            const promissoriasPendentes = context.chart.data.datasets[1].data[dataIndex];
    
            const totalDia = promissoriasPagas + promissoriasPendentes;
    
            if (datasetIndex === 0) {
              return `Pagas: R$ ${promissoriasPagas.toFixed(2).replace('.', ',')}`;
            } else if (datasetIndex === 1) {
              return `Pendentes: R$ ${promissoriasPendentes.toFixed(2).replace('.', ',')}`;
            }
          },
          footer: (tooltipItems) => {
            // Verifica se o tooltipItems contém dados válidos
            const promissoriasPagas = tooltipItems[0] ? tooltipItems[0].parsed.y : 0;
            const promissoriasPendentes = tooltipItems[1] ? tooltipItems[1].parsed.y : 0;
    
            const totalDia = promissoriasPagas + promissoriasPendentes;
    
            return `Total: R$ ${totalDia.toFixed(2).replace('.', ',')}`;
          }
        },
      },
    },
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };
  

  return (
    <div>
      <Navbar />
      <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <div style={{ padding: '20px' }}>
          <div className="d-flex justify-content-between">
            <div>
              <h1>SRI - DASHBOARD</h1>
            </div>
            <div className="d-flex">
              <div style={{ marginBottom: '20px' }}>
                <select className="form-select form-select-lg" onChange={handleTipoRelatorioChange} value={tipoRelatorio}>
                  <option value="M">Mensal</option>
                  <option value="A">Anual</option>
                </select>
              </div>

              {tipoRelatorio === 'A' && (
                <div style={{ marginBottom: '20px' }}>
                  <select className="form-select form-select-lg" onChange={handleAnoChange} value={anoSelecionado}>
                    {['2024', '2025'].map((ano) => (
                      <option key={ano} value={ano}>{ano}</option>
                    ))}
                  </select>
                </div>
              )}

              {tipoRelatorio === 'M' && (
                <>
                  <div style={{ marginBottom: '20px' }}>
                    <select className="form-select form-select-lg" onChange={handleMesChange} value={mesSelecionado}>
                      {['Janeiro', 'Fevereiro', 'Março', 'Novembro'].map((mes) => (
                        <option key={mes} value={mes}>{mes}</option>
                      ))}
                    </select>
                  </div>
                  <div style={{ marginBottom: '20px' }}>
                    <select className="form-select form-select-lg" onChange={handleAnoChange} value={anoSelecionado}>
                      {['2024', '2025'].map((ano) => (
                        <option key={ano} value={ano}>{ano}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>

          <hr />
           <div>
           <h2>Contas a Receber</h2>
           </div>
          

        
          <div className="d-flex justify-content-between mb-5">
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Total das Operações</h5>
                <p className="card-text">{totalOperacoes}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Total Pago</h5>
                <p className="card-text">{totalPago}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Total Pendente</h5>
                <p className="card-text">{totalPendente}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '40px' }}>
            
            <Line data={dadosGraficoOperacoes} options={options} />
          </div>

          <hr />

         
          <div>
          <h2>Finalizadoras</h2>
          </div>
          <div className="d-flex justify-content-between mb-5">
          
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Total de Vendas</h5>
                <p className="card-text">{totalVendas}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Vendas do Dia</h5>
                <p className="card-text">{vendasDia}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Ticket Médio</h5>
                <p className="card-text">{ticketMedio}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '40px' }}>   
            <Bar data={dadosGraficoFinalizadoras} options={optionsFinalizadoras} />
          </div>

          <hr />

          <div>
  <h2>Promissórias</h2>
</div>
<div className="d-flex justify-content-between mb-5">
  
  <div className="card" style={{ width: '30%' }}>
    <div className="card-body">
      <h5 className="card-title">Total de Promissórias</h5>
      <p className="card-text">
        {totalPromissorias.toFixed(2)}
      </p>
      <button className="card-text btn">Explorar</button>
    </div>
  </div>

  
  <div className="card" style={{ width: '30%' }}>
    <div className="card-body">
      <h5 className="card-title">Promissórias Pagas</h5>
      <p className="card-text">
        {totalPromissoriasPago.toFixed(2)}
      </p>
      <button className="card-text btn">Explorar</button>
    </div>
  </div>

  
  <div className="card" style={{ width: '30%' }}>
    <div className="card-body">
      <h5 className="card-title">Promissórias Pendentes</h5>
      <p className="card-text">
        {totalPromissoriasPendente.toFixed(2)} 
      </p>
      <button className="card-text btn">Explorar</button>
    </div>
  </div>
</div>


<div style={{ marginBottom: '40px' }}>
  <Bar data={dadosGraficoPromissorias} options={optionsGraficoPromissorias} />
</div>


        </div>
      </div>
      <FooterBar />
    </div>
  );
}

export default DreDashboard;





