import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'







 

function gerarNotaFiscal(venda, empresa) {
  console.log(venda);
  console.log(empresa);

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const nota = {
    nomeFantasia: empresa[0].RAZAO_SOCIAL,
    cliente: venda.RAZAO,
    data: venda.DATA,
    numeroNota: venda.DOCUMENTO,
    itens: venda.ITENS.map((item) => ({
      item: item.ITEM,
      codigoProd: item.COD_PRODUTO,
      descricao: item.DESCRICAO,
      qtd: item.QTD,
      unitario: item.VENDA,
      desconto: item.DESCONTO,
      total: item.TOTAL,
    })),
    qtdItens: venda.ITENS.reduce((total, item) => total + item.QTD, 0),
    totalNota: venda.VALOR,
    formaPgto: venda.FORMA_RECEBIMENTO && Array.isArray(venda.FORMA_RECEBIMENTO) && venda.FORMA_RECEBIMENTO.length > 0
  ? venda.FORMA_RECEBIMENTO.map((pag) => ({
      descricao: pag.DESCRICAO,
      valor: pag.VALOR,
    }))
  : [], 
    pagamento: {
      data: venda.DATA,
      valor: venda.VALOR,
      tipo: venda.FORMA_PAGAMENTO,
    },
    vendedor: venda.COD_COLABORADOR,
  };

  const docDefinition = {
    pageSize: { width: 58 * 2.83465, height: "auto" },
    pageMargins: [5, 5, 5, 5],
    content: [
      { text: nota.nomeFantasia, style: "header" },
      { text: "================================", fontSize: 8, alignment: "center" },
      { text: `RECIBO Nº ${nota.numeroNota}`, alignment: "center", bold: true, fontSize: 8 },
      { text: "================================", fontSize: 8, alignment: "center" },
      { text: `Data: ${nota.data}    Hora: ${new Date().toLocaleTimeString()}`, fontSize: 7 },
      { text: `Cliente: ${nota.cliente}`, fontSize: 7 },
      { text: "--------------------------------", fontSize: 8, alignment: "center" },
      { text: "ITEM  CODIGO   DESCRIÇÃO       ", alignment: "center", bold: true, fontSize: 8 },
      {
        table: {
          headerRows: 1,
          widths: ["*"], // Ajuste inicial para cabeçalho da descrição
          body: [
            [
              { text: "QTD / VLR.UNIT(R$)/ DES(R$) / SUBTOTAL(R$)", bold: true, fontSize: 7, alignment: "center" },
            ],
            ...nota.itens.map((item) => [
              {
                table: {
                  widths: ["10%", "10%", "20%", "20%", "*"], // Larguras fixas para manter alinhamento
                  body: [
                    // Primeira linha: Descrição
                    [
                      { text: item.item, colSpan: 1, fontSize: 6 },
                      { text: item.codigoProd, colSpan: 2, fontSize: 6 }, {},
                      { text: item.descricao, colSpan: 2, fontSize: 6, bold: true },
                       {} // placeholders para colunas combinadas
                    ],
                    // Segunda linha: Quantidade, X, Valor Unitário, Subtotal
                    [
                      { text: `${item.qtd}`, fontSize: 6, alignment: "left" },
                      { text: "X", fontSize: 6, alignment: "left" },
                      { text: `${item.unitario.toFixed(2)}`, fontSize: 6, alignment: "left" },
                      { text: `${item.desconto.toFixed(2)}`, fontSize: 6, alignment: "right" },
                      { text: `${item.total.toFixed(2)}`, fontSize: 6, alignment: "right" },
                    ],
                  ],
                },
                layout: "noBorders", // Sem bordas entre os itens
              },
            ]),
          ],
        },
        layout: "noBorders", // Sem bordas para a tabela externa
        fontSize: 6,
      },
      { text: "================================", fontSize: 7, alignment: "center" },
      { text: `TOTAL DO PEDIDO: R$ ${nota.totalNota.toFixed(2)}`, fontSize: 7 },
      { text: `QTD. TOTAL DE ITENS: ${nota.qtdItens}`, fontSize: 7 },
      { text: `OPERADOR(a): ${nota.vendedor}`, fontSize: 7 },
      { text: "================================", fontSize: 7, alignment: "center" },
      { text: "PAGAMENTO", bold: true, alignment: "center", fontSize: 7 },
      {
        table: {
          widths: ["auto", "auto", "*"],
          body: [
            [
              { text: "Data", bold: true, fontSize: 6 },
              { text: "Valor", bold: true, fontSize: 6 },
              { text: "Forma de Pagamento", bold: true, fontSize: 6 },
            ],
            ...nota.formaPgto.map((pag) => [
              { text: nota.pagamento.data, fontSize: 6 },
              { text: pag.descricao, fontSize: 6 },
              { text: `R$ ${pag.valor.toFixed(2)}`, fontSize: 6, alignment: "right" },
            ]),
          ],
        },
        layout: "noBorders",
      },
      { text: "================================", fontSize: 7, alignment: "center" },
      { text: "*** NÃO É DOCUMENTO FISCAL ***", bold: true, alignment: "center", fontSize: 7, margin: [0, 5, 0, 0] },
      { text: "OBRIGADO E VOLTE SEMPRE!", alignment: "center", bold: true, fontSize: 8 },
    ],
    styles: {
      header: { fontSize: 9, bold: true, alignment: "center" },
    },
    defaultStyle: {
      fontSize: 7,
    },
  };

  pdfMake.createPdf(docDefinition).open();
}

export default gerarNotaFiscal;





  