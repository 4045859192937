import { useEffect, useState } from "react";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import Navbar from "../navbar/navbar";
import FooterBar from "../footer/footerBar";
import customizeScrollbar from "customsb";
import './caixaGerencial.css';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



function CaixaGerencial(){
  
    const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [caixas,setCaixas] = useState([]);
     const [sResult,setSresult] = useState('N');
     const [colaboradores,setcolaboradores] = useState([]);
     const [caixaSelecionado, setCaixaSelecionado] = useState('');
     const [colaboradorSelecionado, setColaboradorSelecionado] = useState('');
     const [finalizadoraVendas, setFinalizadoraVendas] = useState([]);
     const [dataInical,setDataInicial] = useState(new Date()); 
     const [dataFinal,setDataFinal] = useState(new Date());



     const listaVendas = [{numVenda: 101, data: '22/11/2024', numOp: '003', colaborador: 1, status: 'finalizada', valor: 99},
        {numVenda: 102, data: '22/11/2024', numOp: '01', colaborador: 2, status: 'cancelada', valor: 9.99}
     ]

     useEffect(() => {
        // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
        customizeScrollbar({
          scrollbarWidth: "10px",
          trackBackground: "#333",
          thumbBackground:
            "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
          thumbBorderRadius: "10px",
        });
      }, []);

     let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }

      useEffect(function(){
        api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataInical,
            dataFinal: dataFinal,
            colaborador: colaboradorSelecionado
             
        },config)
        .then(function(response){
            console.log(response.data.dados);
            const dados = response.data.dados;
            setFinalizadoraVendas(dados);

            if(dados === undefined){
              setFinalizadoraVendas([]);
            }
        
      
               
            
        })
        .catch(function (error){
            console.log(error);
        });
      },[colaboradorSelecionado]) 

      useEffect(function(){
    
        api.get(vhostV1('webcash/configcaixa'),config)
          .then(function (response) {
            console.log(response);
            
            setCaixas(response.data.dados); 
            setSresult(response.data.result)
            
                                        
                     
          })
          .catch(function (error) {
           
            console.log(error);
  
                    
          });
    
          
        },[])

        useEffect(function(){
    
            api.post(vhostV1('webcash/usuario/listar'), {
                loja: localStorage.getItem('loja'),
                empresa: 0,
                busca: busca
              },config)
              .then(function (response) {
                console.log(response);
                
                setSresult(response.data.result);
                  setcolaboradores(response.data.dados); 
                         
              })
              .catch(function (error) {
                console.log(error);
                        
              });
        
              
          },[])

          const chartData = {
            labels: finalizadoraVendas.map(card => card.finalizadora), 
            datasets: [
              {
                label: 'Valor Final',
                data: finalizadoraVendas.map(card => card.valor_final), 
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1
              }
            ]
          };

          const options = {
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Valores por Finalizadora'
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => `R$ ${tooltipItem.raw.toFixed(2)}`
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value) {
                    return `R$ ${value}`;
                  }
                }
              }
            }
          };

        return (

        <div>
            <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10 mb-3">
        <div style={{ padding: '20px' }}>
        <div className="d-flex justify-content-between">
            <div>
            <h1>Consolidação Caixa</h1>
            </div>

            <div className="d-flex">
            <div className="mr-3">
            <label htmlFor="" className="form-label">Nº Oper.Caixa</label>
            <div style={{ marginBottom: '20px' }}>
                <select className="form-select form-select-lg" onChange={setCaixaSelecionado}>
                  <option value="">Todos</option>
                  {caixas.map((caixa) => (
                    <option key={caixa.CAIXA} value={caixa.CAIXA}>{caixa.CAIXA}</option>
                  ))}
                  
                </select>
              </div>
            </div>

            <div>
                <label className="form-label">Colaborador</label>
                <div style={{marginBottom: '20px'}}>
                    <select  className="form-select form-select-lg" onChange={setColaboradorSelecionado}>
                        <option value="">Todos</option>
                        {colaboradores.map((colaborador) => (
                            <option key={colaborador.ID} value={colaborador.ID}>{colaborador.NOME}</option>
                        ))}
                    </select>

                </div>
            </div>
            </div>
            
        </div> 

        <hr />

         <div className="d-flex justify-content-between mb-5">
            <div className="card" id="card-caixa" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Nº Vendas</h5>
                <p className="card-text">{9999}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" id="card-caixa" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Valor Total</h5>
                <p className="card-text">{9999.99}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
            <div className="card" id="card-caixa" style={{ width: '30%' }}>
              <div className="card-body">
                <h5 className="card-title">Venda Cancelada</h5>
                <p className="card-text">{999.99}</p>
                <button className="card-text btn">Explorar</button>
              </div>
            </div>
          </div>  


          <div>
            <label className="form-label" >Lista</label>
            <table  className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th scope="col">Nº</th>
                        <th scope="col">Data</th>
                        <th scope="col">Nº Operador</th>
                        <th scope="col">Colaborador</th>
                        <th scope="col">Status</th>
                        <th scope="col">Valor</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {listaVendas.map((venda) => (
                        <tr key={venda.numVenda}>
                        <td>{venda.numVenda}</td>
                        <td>{venda.data}</td>
                        <td>{venda.numOp}</td>
                        <td>{venda.colaborador}</td>
                        <td>{venda.status}</td>
                        <td>{venda.valor}</td>
                        </tr>
                    )

                    )}
                </tbody>

            </table>
            </div> 

            <hr />

            <div>
                <h2>Finalizadoras</h2>
            </div>
            <hr />

            <div className="card-container">
                
              {finalizadoraVendas.map(card => (
            <div className="card" id="card-caixa" key={card.cod_finalizadora}>
             <h2>{card.finalizadora}</h2>
             <p>Valor: R$ {card.valor_final.toFixed(2)}</p>
          </div>
        ))}
            </div>

            <div className="chart-container">
              <Bar data={chartData} options={options} />
            </div>
    




        
        </div>
        </div>
            <FooterBar/>
        </div>
)};

export default CaixaGerencial;