import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../services/api';
import './pedidos.css';
import Navbar from '../navbar/navbar';
import logoNfce from '../../images/nfce.png'
import logoRecibo from '../../images/recibo.png'
import { vhostV1 } from '../funcoes';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import PedidosPdf from '../reports/pedidos/sinteticos';
import VendasPorFinalizadora from '../home/VendasPorFinalizadora';
import VendasPorCupom from '../reports/vendas/vendasporcupom';
import Loading from '../components/loading/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import CupomFiscal from '../reports/nfe/CupomFiscal';
import cupom from '../reports/nfe/venda';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Widget from '../components/widget/widget';
import customizeScrollbar from "customsb";
import VendaDashboardPdf from '../reports/pedidos/vendaDashboardPdf';
import ColumnChart from '../components/widget/chart';
import FooterBar from '../footer/footerBar';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PedidosDashboard() {

    const [pedidos,setPedidos] = useState([]); 
    const [result, setResult] = useState(0);
    const [sResult,setSresult] = useState(0);
    const [tpRelatorio,setTipoRelatorio] = useState(0);
    const [tipo,setTipo] = useState('TODOS');
    const [caixa,setCaixa] = useState('TODOS');  
    const [dataInical,setDataInicial] = useState(new Date()); 
    const [dataFinal,setDataFinal] = useState(new Date());
    const [load,setLoad] = useState(false);
    const [confirmaEmissao,setConfirmaEmissao] = useState(false);
    const [erroEmissao,setErroEmissao] = useState(false);
    const [base64Data, setBase64Data] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [msgErro,setmsgErro] = useState('');
    const [rankingProduto, setRankingProduto] = useState([]);
    const [totalMes,setTotalMes] = useState(0);   
    const [totalDia,setTotalDia] = useState(0);   
    const [qtdVenda,setQtdVenda] = useState(0); 
    const [tiket,setTiket] = useState(0);
    const [rankVendedor, setRankVendedor] = useState([]);
    const [finalizadoraVenda, setFinalizadoraVendas] = useState([]);
    const [vendaPix, setVendaPix] = useState(0);
    const [vendaDinheiro, setVendaDinheiro] = useState(0);
    const [vendaCartao, setVendaCartao] = useState(0);
    const [busca, setBusca] = useState('');
    const [colaboradores, setcolaboradores] = useState([]);
    const [operador, setOperador] = useState('');



    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }

        async function fetchBase64Data(schave){
            try {
              setLoad(true)
                const response = await api.post(vhostV1('webcash/nfe/danfepdf'),{
                loja: localStorage.getItem('loja'),
                empresa: 0,
                chave:schave
              },config);
              setLoad(false)
              console.log(response)
              setBase64Data(response.data.dados.data.base64);
              convertAndOpenPDF(response.data.dados.data.base64);
            } catch (error) {
              console.error('Erro ao buscar base64 da API:', error);
            }
          };
    
          const convertAndOpenPDF = (base64String) => {
            const byteCharacters = atob(base64String);
            const byteArrays = [];
        
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
        
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
        
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
        
            const pdfBlob = new Blob(byteArrays, { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
        
            window.open(pdfUrl, '_blank');
          };    


 // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

  useEffect(function(){
    api.post(vhostV1('webcash/dashboard/produtoMaisVendido'),{
        loja: localStorage.getItem('loja'),
        empresa: 0,
        data: dataInical,
        dataFinal: dataFinal,
        colaborador: operador
    }, config)
    .then(function (response){
        console.log(response);
        setRankingProduto(response.data.dados);
        
    })
    .catch(function (error){
        console.log(error);
    })
},[])

if(rankingProduto !== undefined){
rankingProduto.sort((a, b) => b.qtd - a.qtd);}


useEffect(function(){
  api.post(vhostV1('webcash/dashboard/rankVendedor'),{
      loja: localStorage.getItem('loja'),
      empresa: 0,
      data: dataInical,
      dataFinal: dataFinal,
      colaborador: operador
  }, config)
  .then(function (response){
      console.log(response);
      setRankVendedor(response.data.dados);
      if(response && response.data && response.data.dados){
          setResult(1);
      }
      
      
  })
  .catch(function (error){
      console.log(error);
  })
},[])


       
// useEffect(function(){
//   api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
//       loja: localStorage.getItem('loja'),
//       empresa: 0,
//       data: dataInical
       
//   },config)
//   .then(function(response){
//       console.log(response.data.dados);
//       const dados = response.data.dados;
//       setFinalizadoraVendas(dados);
//       const somarValoresPorCodigo = (dados, codigo) => {
//           return dados
//               .filter(dado => dado.cod_finalizadora === codigo)  // Filtra os objetos com o código desejado
//               .reduce((acumulador, dado) => acumulador + dado.valor_final, 0);  // Soma os valores finais
//       };

//       setVendaDinheiro(somarValoresPorCodigo(dados, 1));
//       setVendaCartao(somarValoresPorCodigo(dados, 2));  
//       setVendaPix(somarValoresPorCodigo(dados, 3));          
      
//   })
//   .catch(function (error){
//       console.log(error);
//   });
// },[dataInical])  

useEffect(function(){
  api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      data: dataInical,
      dataFinal: dataFinal,
      colaborador: operador
       
  },config)
  .then(function(response){
      console.log(response.data.dados);
      const dados = response.data.dados;
      setFinalizadoraVendas(dados);
  

         
      
  })
  .catch(function (error){
      console.log(error);
  });
},[]) 

useEffect(function(){
    
    api.post(vhostV1('webcash/usuario/listar'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        busca: busca
      },config)
      .then(function (response) {
        console.log(response);
        
        
          setcolaboradores(response.data.dados); 
                 
      })
      .catch(function (error) {
        console.log(error);
                
      });

      
  },[busca])


const sjson = {
    loja: localStorage.getItem('loja'),
    empresa: 0,
    data: dataInical,
    dataFinal: dataFinal,
    colaborador: operador
    };

    console.log( sjson);
    

useEffect(function(){
  api.post(vhostV1('webcash/dashboard/widget'), {
    loja: localStorage.getItem('loja'),
    empresa: 0,
    data: dataInical,
    dataFinal: dataFinal,
    colaborador: operador
    },config)
  .then(function (response) {        
    console.log(response);
    setTotalMes(response.data.dados.total);           
    setTotalDia(response.data.dados.venda);           
    setQtdVenda(response.data.dados.qtd);           
    setTiket(response.data.dados.ticket);           

  })
  .catch(function (error) {
    console.log(error);
            
  });
          
},[])






const handleClick = () => {
  VendaDashboardPdf({
      finalizadoraVenda,
      tiket,
      rankVendedor,
      rankingProduto,
      totalDia,
      qtdVenda
  });
};


   function filtraDashboard(){
   
        api.post(vhostV1('webcash/dashboard/widget'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          data: dataInical,
          dataFinal: dataFinal,
          colaborador: operador
          },config)
        .then(function (response) {        
          console.log(response);
          setTotalMes(response.data.dados.total);           
          setTotalDia(response.data.dados.venda);           
          setQtdVenda(response.data.dados.qtd);           
          setTiket(response.data.dados.ticket);           
      
        })
        .catch(function (error) {
          console.log(error);
                  
        });
                
      

     
        api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataInical,
            dataFinal: dataFinal,
            colaborador: operador
             
        },config)
        .then(function(response){
            console.log(response.data.dados);
            const dados = response.data.dados;
            setFinalizadoraVendas(dados);
        
      
               
            
        })
        .catch(function (error){
            console.log(error);
        });
      

    
        api.post(vhostV1('webcash/dashboard/produtoMaisVendido'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataInical,
            dataFinal: dataFinal,
            colaborador: operador
        }, config)
        .then(function (response){
            console.log(response);
            setRankingProduto(response.data.dados);
            
        })
        .catch(function (error){
            console.log(error);
        })
    
    
    if(rankingProduto !== undefined){
    rankingProduto.sort((a, b) => b.qtd - a.qtd);}
    
    
   
      api.post(vhostV1('webcash/dashboard/rankVendedor'),{
          loja: localStorage.getItem('loja'),
          empresa: 0,
          data: dataInical,
          dataFinal: dataFinal,
          colaborador: operador
      }, config)
      .then(function (response){
          console.log(response);
          setRankVendedor(response.data.dados);
          if(response && response.data && response.data.dados){
              setResult(1);
          }
          
          
      })
      .catch(function (error){
          console.log(error);
      })
    
   }









  return <div>
     <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        
       
        <div className="d-flex justify-content-between">
              <h2 className="d-inline">Vendas - Dashboard</h2>
              <div>
                
                <button type="button" className="btn btn-secondary btn-cli" data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
                <button type="button" onClick={handleClick} className="btn btn-danger btn-cli" data-bs-dismiss="modal"><i className='bi bi-printer-fill'></i>Imprimir</button>
                {/*<button type="button" className="btn btn-danger btn-cli"  data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
                <SalesReport/>*/}
              </div>
        </div> 
        
        <hr />

        <div className="row">
        <div className="container-fluid">
  
  <div className="row widgets" id='bloco-widget'>
      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Faturamento do Mês
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalMes).toFixed(2)}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              Total de Venda (Dia)
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalDia).toFixed(2)}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                              Ticket Médio
                          </div>
                          <div className="row no-gutters align-items-center">
                              <div className="col-auto">
                                  <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">R$ {Number(tiket).toFixed(2)}</div>
                              </div>
                          </div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Cupons Emitidos
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{qtdVenda}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-comments fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

 
  <div className="row mb-4">
      <div className="col-12">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="grid-container">
                      
                      <div className="grid-item">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Gráfico de Vendas (R$)
                          </div>
                          <ColumnChart finalizadoraVenda={finalizadoraVenda}/>
                      </div>

                  
                      <div className="grid-item">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Gráficos de Venda (Descrição)
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Finalizadora</th>
                                      <th scope='col'>Venda</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {finalizadoraVenda !== undefined ?
                                      finalizadoraVenda.map((item) => (
                                          <tr key={item.finalizadora}>
                                              <td>{item.finalizadora}</td>
                                              <td>R$ {Number(item.valor_final).toFixed(2)}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

 
  <div className="row mb-4">
      <div className="col-xl-6 col-lg-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Produtos mais vendidos
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Descrição</th>
                                      <th scope='col'>Quantidade</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {rankingProduto !== undefined ?
                                      rankingProduto.map((item) => (
                                          <tr key={item.descricao}>
                                              <td>{item.descricao}</td>
                                              <td>{item.qtd}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      
      <div className="col-xl-6 col-lg-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Ranking Vendedores
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Código</th>
                                      <th scope='col'>Nome</th>
                                      <th scope='col'>Total</th>
                                      <th scope='col'>Vendas</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {result === 1 ?
                                      rankVendedor.map((vendedor) => (
                                          <tr key={vendedor.cod_colaborador}>
                                              <td>{vendedor.cod_colaborador}</td>
                                              <td>{vendedor.nome}</td>
                                              <td>R$ {Number(vendedor.total).toFixed(2)}</td>
                                              <td>{vendedor.qtd}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
        </div>

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Vendas</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>Tipo do relatório </h5> 
                  <div className="form-check">
                    <input className="form-check-input" value={0}  type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Sintético
                    </label>
                  </div>
                 

                  <div className="form-check">
                    <input className="form-check-input" value={2}  type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Finalizadora
                    </label>
                  </div>

                    
                    <hr />

                   <div className="row">
                     <h5>Período  </h5>   
                       <div className="datas col-8 offset-2">
                         <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className='form-control'/>
                         <br />
                         <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className='form-control'/>
                         </div>
                          
                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                              
                 
                  

                </div>
              </div>
            </div>
          </div>


          <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                    {/* <div className="row">
                      
                    <div >
                        <select className='form-control' name="status" id="status" onChange={(e)=> setTipoRelatorio(e.target.value)}>
                            <option value={0}>Lista</option>
                            <option value={1}>Agrupado por Finalizadora</option>
                            <option value={2}>Agrupado por Cupom</option>
                        </select>
                    </div>                       
                    </div>       */}
                                       

                   {/* <div className="row">
                    <div>
                     <h5>Tipo  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" id="status" onChange={(e)=> setTipo(e.target.value)}>
                            <option value="TODOS">Todos</option>
                            <option value="RECIBO">Recibo</option>
                            <option value="NFCE">NFCe</option>
                        </select>
                    </div>
                    
                   </div>
                   </div>

                   <div>

                   <h5>Caixa  </h5>   
                     <div>
                     <div className="form-control d-inline me-3">
                        <select name="status" onChange={(e)=> setCaixa(e.target.value)} id="status" >
                            <option value="%">Todos</option>
                            <option value="001">001</option>
                            <option value="002">002</option>
                            <option value="003">003</option>
                            <option value="004">004</option>
                        </select>
                     </div>
                    
                     </div>

                     </div>
                          
                     </div> */}
                     <br />

                     {/* <div className="row">
                    
                    </div> */}


                    <div className="row">
                     <h5>Período</h5>   
                     <div>
                     <div className='d-flex col-12'>           
                        <input type="date" onChange={(e)=> setDataInicial(e.target.value+"T10:10:00.000Z")} className="form-control mr-2" />
                         
                        <input type="date" onChange={(e)=> setDataFinal(e.target.value+"T10:10:00.000Z")} className="form-control" />
                    </div>
                     </div>
                     </div>

                     <hr />

                     <div className='row'>
                        <h5>Operador</h5>
                        
                        <div >
                        <select className='form-control' name="status" id="status" value={operador} onChange={(e)=> setOperador(e.target.value)}>
                            <option value="">Selecione um operador</option>
                           {colaboradores.map((tipo) => (<option key={tipo.ID} value={tipo.ID}> {tipo.NOME} </option>))}
                            
                        </select>
                    </div>  
                    

                     </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <div>                  
                  { tpRelatorio===0?
                  <button type="button"  onClick={filtraDashboard} className="btn btn-secondary btn-cli" data-bs-dismiss="modal">Ativar</button>
                  :null}
                  
                  
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
       </div>   

       <FooterBar/> 
  </div>









}

export default PedidosDashboard;