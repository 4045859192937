import { Link, Navigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import Navbar from "../navbar/navbar";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import SweetAlert from "react-bootstrap-sweetalert";
import FooterBar from "../footer/footerBar";
import customizeScrollbar from "customsb";


function NovaFichaTecnica(){

  useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

    let config = {
        headers: {
          'Authorization': localStorage.getItem("token"),
          'Content-Type': 'application/json'
        },
        'timeout': 10000
      }
    
    const inputs = document.querySelectorAll("input, select");

    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });

    const {cod} = useParams();
    const {tag} = useParams();

     const [componentes, setComponentes] = useState([]);
     const [descricaoComponente, setDescricaoComponente] = useState('');
     const [compraComponente, setCompraComponente] = useState(0);
     const [quantidade, setQuantidade] = useState('');
     const [unidadeComponente, setUnidadeComponente] = useState('UN');
     
  
     const [cod_produto,setCod_produto]     = useState('');
     const [descricao,setDescricao]         = useState('');
     const [cod_grupo,setCod_grupo]         = useState(0);
     const [unidade,setUnidade]             = useState('UN');
     const [venda,setVenda]                 = useState(0);
     const [vendaDefault,setvendaDefault]   = useState(0);
     const [ncm,setNcm]                     = useState('');
     const [sucesso,setSucesso]             = useState('N')
     const [mensagem, setMensagem]          = useState('');
     const [grupos,setGrupos]               = useState([]);
     const [compra,setCompra]               = useState(0);
     const [compraDefault,setCompraDefault] = useState(0);
     const [estoque,setEstoque]             = useState(0);
     const [adicionais,setAdicionais]       = useState(0);
     const [markup,setMarkup]               = useState(0);
     const [markupDefault,setMarkupDefalt]  = useState(0);
     const [vendaMarkup,setVendaMarkup]     = useState(0);
     const [localizacao,setLocalizacao]     = useState(0);
     const [erroValidacao, setErrovalidacao] = useState(false);
     const [msgErro, setmsgErro] = useState('');
     const [ingredientes, setIngredientes] = useState([]);
     const [preparo, setPreparo] = useState('');
  
     const [buscaNcm,setBuscaNcm]= useState('');
     const [Ncms,setNcms]        = useState([]);
     const [sResult,setSresult]  = useState(0);
     const [verificaProd, setVerificaProd] = useState('1');

     const adicionarComponente = () => {
      if (descricaoComponente && compraComponente && quantidade) {
        setComponentes([
          ...componentes,
          { DESCRICAO: descricaoComponente.toLocaleUpperCase(), COMPRA: compraComponente ,QTD: quantidade, UNIDADE: unidadeComponente }
        ]);
        setDescricaoComponente('');
        setCompraComponente(0);
        setQuantidade('');
        setUnidadeComponente('UN');
      } else {
        setmsgErro('Todos os campos são obrigatórios');
        setErrovalidacao(true);
        
      }
    };

    

     useEffect(function(){  
      
        api.post(vhostV1('webcash/produto/Pesquisa/'+cod), {
            loja: localStorage.getItem('loja'),
            empresa: 0        
          },config)
          .then(function (response) {
            
            
            if (response.data.result === 1){
             setCod_produto(response.data.dados.COD_PRODUTO);  
             setDescricao(response.data.dados.DESCRICAO);  
             setVenda(response.data.dados.VENDA);
             
             setCod_grupo(response.data.dados.COD_GRUPO);  
             setCompra(response.data.dados.COMPRA);  
             setCompraDefault(response.data.dados.COMPRA);  
            
             setEstoque(response.data.dados.ESTOQUE);
              }
    
                                   
          });
    
          
      },[])
    

    function buscaIten(){
        api.post(vhostV1('webcash/produto/retornaproduto/'+cod_produto), {
          loja: localStorage.getItem('loja'),
          empresa: 0         
        },config)
        .then(function (response) { 
          
         if(response && response.data && response.data.dados){
          setVerificaProd(response.data.dados.COD_PRODUTO);
          console.log(response)
    
          
          
           }
         else{
           console.log("não busquei")
         }  
          
         })
        .catch(function (error) {
         
          console.log(error);
                  
        });
         };

         

    return <div className="conteiner-fluid titulo ">
    <Navbar/>
    <div className="offset-lg-1 col-lg-10">
          <h2>Ficha Técnica: {descricao}</h2>                   
          <hr />
           <div className="conteiner-fluid">
           <form id="table-servico" className="row tela" >
              
              <div id="table-servico" className="col-2">
                  <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                  <input type="text" value={cod_produto} onBlur={(e)=> buscaIten(e.target.value)} onChange={(e)=> setCod_produto(e.target.value)} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off" readOnly/>
              </div>
              <div id="table-servico" className="col-6">
                  <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>
                  <input type="text" value={descricao} onChange={(e)=> setDescricao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off" readOnly/>
              </div>
              
             

              <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">COMPRA</label>
                  <NumericFormat className="form-control"
                                 id="inputCompra" 
                                 value={compraDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                 tabIndex="8"
                                 allowLeadingZeros
                                 readOnly
                                 decimalSeparator=','
                                 thousandSeparator='.'
                                 decimalScale={2}
                                 prefix="R$ "
                                 onValueChange={(values,suorceInfo)=>{
                                   setCompra(values.value);
                                 }} 
                            />     
              </div>   
                 
              


                   

                                   

              <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">VENDA</label>
                  <NumericFormat className="form-control"
                                 id="inputCompra" 
                                 value={venda.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                 tabIndex="8"
                                 allowLeadingZeros
                                 readOnly
                                 decimalSeparator=','
                                 thousandSeparator='.'
                                 decimalScale={2}
                                 prefix="R$ "
                                 onValueChange={(values,suorceInfo)=>{
                                   setCompra(values.value);
                                 }} 
                            />     
              </div>        



              <h3 className="mt-3">Adicione os componentes</h3>

              <hr />

               <div className="col-5">
              <label htmlFor="inputDescricaoComponente" className="form-label">DESCRICAO</label>
              <input
                type="text"
                value={descricaoComponente}
                onChange={(e) => setDescricaoComponente(e.target.value)}
                className="form-control"
                id="inputDescricaoComponente"
                tabIndex="2"
                autoComplete="off"
              />
            </div>
            
            <div className="col-2">
              <label htmlFor="inputQuantidadeComponente" className="form-label">QUANTIDADE</label>
              <input
                type="number"
                value={quantidade}
                onChange={(e) => setQuantidade(e.target.value)}
                className="form-control"
                id="inputQuantidadeComponente"
                tabIndex="3"
              />
            </div>

            <div className="col-md-1">
                  <label htmlFor="inputState" className="form-label">UNIDADE</label>
                  <select id="inputUnidade" value={unidadeComponente} onChange={(e)=> setUnidadeComponente(e.target.value)} className="form-select" tabIndex="4" >
                      <option value={'UN'}>UN</option>
                      <option value={'KG'}>KG</option>
                      <option value={'CX'}>CX</option>                    
                      <option value={'LT'}>LT</option>                    
                  </select>
              </div>

            <div className="col-2">
              <label htmlFor="inputCompraComponente" className="form-label">COMPRA</label>
              <NumericFormat
                className="form-control"
                id="inputCompraComponente"
                value={compraComponente}
                tabIndex="4"
                thousandSeparator='.'
                decimalSeparator=','
                decimalScale={2}
                prefix="R$ "
                onValueChange={(values) => setCompraComponente(values.floatValue)}
              />
            </div>

            <div className="col-2 d-flex align-items-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={adicionarComponente}
              >
                Adicionar Componente
              </button>
            </div> 

               


          



  
              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Navigate to='/app/fichaTecnica' /> : null}
  
              </form> 

              <div className="mt-4">
            <h4>Componentes Adicionados</h4>
            {componentes.length > 0 ? (
              <ul className="list-group">
                {componentes.map((componente, index) => (
                  <li key={index} className="list-group-item">
                    <strong>Descrição:</strong> {componente.DESCRICAO} | <strong>Quantidade:</strong> {componente.QTD} | <strong>Tipo Unitário </strong> {componente.UNIDADE} | <strong>Compra:</strong> {componente.COMPRA.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
                  </li>
                ))}
              </ul>
            ) : (
              <p>Nenhum componente adicionado</p>
            )}
          </div>

          <hr />

          <div class="mb-3 mt-3">
            <label for="observacao" class="form-label">MODO DE PREPARO</label>
            <textarea class="form-control" id="observacao" rows="5" value={preparo} onChange={(e) => setPreparo(e.target.value)} placeholder="Digite as instruções aqui..."></textarea>
            </div> 

          <div className="col-12">
              
                  <Link to="/app/fichaTecnica" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                  {tag != '0'?(
                             <button type="submit" className="btn btn-primary butao" tabIndex="13">Salvar</button>
                             ):null}

              </div>

       </div>
       {
      erroValidacao ? 
      <SweetAlert
         danger
         confirmBtnText="OK"
         confirmBtnBsStyle="danger"
         title="Erro"
         onConfirm={() => setErrovalidacao(false)}                            
       >
         {msgErro}
       </SweetAlert>:null
      
     }
   </div>  
   <FooterBar/>
</div>
};

export default NovaFichaTecnica;