import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import './navbar.css';
import Logo from '../../images/SRI.png'
import SweetAlert from "react-bootstrap-sweetalert";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";

function Navbar(){

  const [clickVersao, setClickVersao] = useState(false);
  const [msgVersao, setMsgVersao] = useState('');
  

  
  const versao = 'Versão: 1.0.3 \n | Data: 21/11/2024'

    function logout(){
       localStorage.removeItem('token');
       localStorage.removeItem('colaborador');
       document.location = '/';
    }

   
    


    function botaoVersao(){
      {setMsgVersao(versao);
        setClickVersao(true)
      }
    }

   


    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">

    <div className="container-fluid">
            
        <a className="navbar-brand" href="/app/home">
          <img src={Logo} alt="" height="28" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav" translate="no">
            <li className="nav-item">
              <Link to="/app/home" className="nav-link" aria-current="page" id="nav-font" >Home</Link>
            </li>
            
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="nav-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Produtos
            </a>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" id="nav-font" href="/app/produto">Produto</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/grupo">Grupos</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/fichaTecnica">Ficha técnica</a></li> 
                <li><a className="dropdown-item" id="nav-font" href="/app/imagemProduto">Imagens</a></li>
                {/* <li><a className="dropdown-item" id="nav-font" href="/app/produtoservico">Serviço</a></li>               */}
            </ul>

            
            </li>

            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="nav-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Estoque
            </a>
            <ul className="dropdown-menu">
                
                <li><a className="dropdown-item" id="nav-font" href="/app/estoque/entrada">Entrada de Estoque</a></li>
                {/* <li><a className="dropdown-item" id="nav-font" href="/app/estoque/compra">Compra</a></li> */}
              
                
            </ul>

            
            </li> 

            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="nav-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Cadastros
            </a>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" id="nav-font" href="/app/cadastro/c">Cliente</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/cadastro/f">Fornecedor</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/colaborador">Colaborador</a></li>
                {/* <li><a className="dropdown-item" id="nav-font" href="/app/cadastroMotoqueiro">Motoqueiro</a></li> */}
            </ul>
            </li>
            
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="nav-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Pedidos
            </a>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" id="nav-font" href="/app/listapedidos">Venda</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/pedidosDashboard">Vendas - Dashboard</a></li>
            </ul>
            </li>


            
           
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="nav-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Nfe
            </a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" id="nav-font" href="/app/nfe">NFe</a></li>
              <li><a className="dropdown-item" id="nav-font" href="/app/nfeDashboard">Nfe - Dashboard</a></li>
            </ul>
            
         </li> 
            
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="nav-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Financeiro
            </a>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" id="nav-font" href="/app/banco">Banco</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/contas">Contas</a></li>
                {/* <li><a className="dropdown-item" id="nav-font" href="/app/tesouraria">Tesouraria</a></li> */}
                <li><a className="dropdown-item" id="nav-font" href="/app/finalizadora">Finalizadora</a></li>
                <li><a className="dropdown-item" id="nav-font" href="/app/dreDashboard">D.R.E - Dashboard</a></li>
            </ul>
            </li>

            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="nav-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Gerencial
            </a>
            <ul className="dropdown-menu">
                <li><a className="dropdown-item" id="nav-font" href="/app/empresa">Empresas</a></li>               
                <li><a className="dropdown-item" id="nav-font" href="/app/pdv">Pdv</a></li>   
                <li><a className="dropdown-item" id="nav-font" href="/app/caixaGerencial">Caixa Gerencial</a></li>
                <li> <button className="dropdown-item" onClick={botaoVersao} id="nav-font">Versão</button></li>          
            </ul>
            </li>
     
            <li className="nav-item">
              <a href="" onClick={logout} className="nav-link" id="nav-font" aria-current="page" >Sair</a>
            </li>
          </ul>
        </div>    
      
    </div>

    
    {clickVersao && (
                  <SweetAlert
                      info
                      confirmBtnText="OK"
                      confirmBtnBsStyle="info"
                      title="Versão"
                      onConfirm={() => setClickVersao(false)}
                  >
                      {msgVersao}
                  </SweetAlert>
              )}



    <i className="bi bi-person-circle "> {'  '+localStorage.getItem("usuario")}</i>
    
  </nav>;
  }


export default Navbar;