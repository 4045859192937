const codigosSt = [
    {
      COD_ST: "0000",
      DESCRICAO: "TRIBUTADA INTEGRALMENTE",
      ORIGEM: "0"
    },
    {
      COD_ST: "0010",
      DESCRICAO: "TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0020",
      DESCRICAO: "COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "0"
    },
    {
      COD_ST: "0030",
      DESCRICAO: "ISENTA OU NAO TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0040",
      DESCRICAO: "ISENTA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0041",
      DESCRICAO: "NAO TRIBUTADA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0050",
      DESCRICAO: "SUSPENSAO",
      ORIGEM: "0"
    },
    {
      COD_ST: "0051",
      DESCRICAO: "DIFERIMENTO",
      ORIGEM: "0"
    },
    {
      COD_ST: "0060",
      DESCRICAO: "ICMS COBRADO ANTERIORMENTE POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0070",
      DESCRICAO: "COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "0"
    },
    {
      COD_ST: "0090",
      DESCRICAO: "OUTRAS",
      ORIGEM: "0"
    },
    {
      COD_ST: "0101",
      DESCRICAO: "SIMPLES NACIONAL - TRIBUTADA PELO SIMPLES NACIONAL COM PERMISSAO DE CRÉDITO",
      ORIGEM: "1"
    },
    {
      COD_ST: "0102",
      DESCRICAO: "SIMPLES NACIONAL - TRIBUTADA PELO SIMPLES NACIONAL SEM PERMISSAO DE CRÉDITO",
      ORIGEM: "1"
    },
    {
      COD_ST: "0103",
      DESCRICAO: "SIMPLES NACIONAL - ISENCAO DO ICMS NO SIMPLES NACIONAL PARA FAIXA DE RECEITA BRUTA",
      ORIGEM: "1"
    },
    {
      COD_ST: "0201",
      DESCRICAO: "SIMPLES NACIONAL - TRIBUTADA PELO SIMPLES NACIONAL COM PERMISSAO DE CRÉDITO E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "1"
    },
    {
      COD_ST: "0202",
      DESCRICAO: "SIMPLES NACIONAL - TRIBUTADA PELO SIMPLES NACIONAL SEM PERMISSAO DE CRÉDITO E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "1"
    },
    {
      COD_ST: "0203",
      DESCRICAO: "SIMPLES NACIONAL - ISENCAO DO ICMS NO SIMPLES NACIONAL PARA FAIXA DE RECEITA BRUTA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "1"
    },
    {
      COD_ST: "0300",
      DESCRICAO: "SIMPLES NACIONAL - IMUNE",
      ORIGEM: "1"
    },
    {
      COD_ST: "0400",
      DESCRICAO: "SIMPLES NACIONAL - NAO TRIBUTADA PELO SIMPLES NACIONAL",
      ORIGEM: "1"
    },
    {
      COD_ST: "0500",
      DESCRICAO: "SIMPLES NACIONAL - ICMS COBRADO ANTERIORMENTE POR SUBSTITUICAO TRIBUTARIA (SUBSTITUINDO) OU POR ANTECIPACAO",
      ORIGEM: "1"
    },
    {
      COD_ST: "0900",
      DESCRICAO: "SIMPLES NACIONAL - OUTROS",
      ORIGEM: "1"
    },
    {
      COD_ST: "1000",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - TRIBUTADA INTEGRALMENTE",
      ORIGEM: "2"
    },
    {
      COD_ST: "1010",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1020",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "2"
    },
    {
      COD_ST: "1030",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - ISENTA OU NAO TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1040",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - ISENTA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1041",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - NAO TRIBUTADA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1050",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - SUSPENSAO",
      ORIGEM: "2"
    },
    {
      COD_ST: "1051",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - DIFERIMENTO",
      ORIGEM: "2"
    },
    {
      COD_ST: "1060",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - ICMS COBRADO ANTERIORMENTE POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1070",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "1090",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA - OUTRAS",
      ORIGEM: "2"
    },
    {
      COD_ST: "1101",
      DESCRICAO: "SIMPLES NACIONAL - TRIBUTADA PELO SIMPLES NACIONAL COM PERMISSAO DE CRÉDITO",
      ORIGEM: "1"
    },
    {
      COD_ST: "2000",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - TRIBUTADA INTEGRALMENTE",
      ORIGEM: "2"
    },
    {
      COD_ST: "2010",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2020",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "2"
    },
    {
      COD_ST: "2030",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - ISENTA OU NAO TRIBUTADA E COM COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2040",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - ISENTA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2041",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - NAO TRIBUTADA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2050",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - SUSPENSAO",
      ORIGEM: "2"
    },
    {
      COD_ST: "2051",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - DIFERIMENTO",
      ORIGEM: "2"
    },
    {
      COD_ST: "2060",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - ICMS COBRADO ANTERIORMENTE POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2070",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR SUBSTITUICAO TRIBUTARIA",
      ORIGEM: "2"
    },
    {
      COD_ST: "2090",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO - OUTRAS",
      ORIGEM: "2"
    },
    {
      COD_ST: "3000",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-TRIBUTADA INTEGRALMENTE",
      ORIGEM: "3"
    },
    {
      COD_ST: "3010",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "3"
    },
    {
      COD_ST: "3020",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "3"
    },
    {
      COD_ST: "3030",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-ISENTA/NAO TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "3"
    },
    {
      COD_ST: "3040",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-ISENTA",
      ORIGEM: "3"
    },
    {
      COD_ST: "3041",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-NAO TRIBUTADA",
      ORIGEM: "3"
    },
    {
      COD_ST: "3050",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-SUSPENSAO",
      ORIGEM: "3"
    },
    {
      COD_ST: "3051",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-DIFERIMENTO",
      ORIGEM: "3"
    },
    {
      COD_ST: "3060",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-ICMS COBRADO ANTERIORMENTE POR ST",
      ORIGEM: "3"
    },
    {
      COD_ST: "3070",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR ST",
      ORIGEM: "3"
    },
    {
      COD_ST: "3090",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO SUPERIOR A 40%-OUTRAS",
      ORIGEM: "3"
    },
    {
      COD_ST: "4000",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-TRIBUTADA INTEGRALMENTE",
      ORIGEM: "4"
    },
    {
      COD_ST: "4010",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "4"
    },
    {
      COD_ST: "4020",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "4"
    },
    {
      COD_ST: "4030",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-ISENTA/NAO TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "4"
    },
    {
      COD_ST: "4040",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-ISENTA",
      ORIGEM: "4"
    },
    {
      COD_ST: "4041",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-NAO TRIBUTADA",
      ORIGEM: "4"
    },
    {
      COD_ST: "4050",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-SUSPENSAO",
      ORIGEM: "4"
    },
    {
      COD_ST: "4051",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-DIFERIMENTO",
      ORIGEM: "4"
    },
    {
      COD_ST: "4060",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-ICMS COBRADO ANTERIORMENTE POR ST",
      ORIGEM: "4"
    },
    {
      COD_ST: "4070",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR ST",
      ORIGEM: "4"
    },
    {
      COD_ST: "4090",
      DESCRICAO: "NACIONAL, CUJA PRODUCAO TENHA SIDO FEITA EM CONFORMIDADE COM OS PROCESSOS PRODUTIVOS BASICOS-OUTRAS",
      ORIGEM: "4"
    },
    {
      COD_ST: "5000",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-TRIBUTADA INTEGRALMENTE",
      ORIGEM: "5"
    },
    {
      COD_ST: "5010",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "5"
    },
    {
      COD_ST: "5020",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "5"
    },
    {
      COD_ST: "5030",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-ISENTA/NAO TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "5"
    },
    {
      COD_ST: "5040",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-ISENTA",
      ORIGEM: "5"
    },
    {
      COD_ST: "5050",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-SUSPENSAO",
      ORIGEM: "5"
    },
    {
      COD_ST: "5051",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-DIFERIMENTO",
      ORIGEM: "5"
    },
    {
      COD_ST: "5060",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-ICMS COBRADO ANTERIORMENTE POR ST",
      ORIGEM: "5"
    },
    {
      COD_ST: "5070",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR ST",
      ORIGEM: "5"
    },
    {
      COD_ST: "5090",
      DESCRICAO: "NACIONAL, MERCADORIA OU BEM COM CONTEUDO DE IMPORTACAO INFERIOR OU IGUAL A 40%-OUTRAS",
      ORIGEM: "5"
    },
    {
      COD_ST: "6000",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-TRIBUTADA INTEGRALMENTE",
      ORIGEM: "6"
    },
    {
      COD_ST: "6010",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "6"
    },
    {
      COD_ST: "6020",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "6"
    },
    {
      COD_ST: "6030",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-ISENTA/NAO TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "6"
    },
    {
      COD_ST: "6040",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-ISENTA",
      ORIGEM: "6"
    },
    {
      COD_ST: "6041",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-NAO TRIBUTADA",
      ORIGEM: "6"
    },
    {
      COD_ST: "6050",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-SUSPENSAO",
      ORIGEM: "6"
    },
    {
      COD_ST: "6051",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-DIFERIMENTO",
      ORIGEM: "6"
    },
    {
      COD_ST: "6060",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-ICMS COBRADO ANTERIORMENTE POR ST",
      ORIGEM: "6"
    },
    {
      COD_ST: "6070",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR ST",
      ORIGEM: "6"
    },
    {
      COD_ST: "6090",
      DESCRICAO: "ESTRANGEIRA - IMPORTACAO DIRETA, SEM SIMILAR NACIONAL-OUTRAS",
      ORIGEM: "6"
    },
    {
      COD_ST: "7000",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-TRIBUTADA INTEGRALMENTE",
      ORIGEM: "7"
    },
    {
      COD_ST: "7010",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "7"
    },
    {
      COD_ST: "7020",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-COM REDUCAO DE BASE DE CALCULO",
      ORIGEM: "7"
    },
    {
      COD_ST: "7030",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-ISENTA/NAO TRIBUTADA E COM COBRANCA DO ICMS POR ST",
      ORIGEM: "7"
    },
    {
      COD_ST: "7040",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-ISENTA",
      ORIGEM: "7"
    },
    {
      COD_ST: "7041",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-NAO TRIBUTADA",
      ORIGEM: "7"
    },
    {
      COD_ST: "7050",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-SUSPENSAO",
      ORIGEM: "7"
    },
    {
      COD_ST: "7051",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-DIFERIMENTO",
      ORIGEM: "7"
    },
    {
      COD_ST: "7060",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-ICMS COBRADO ANTERIORMENTE POR ST",
      ORIGEM: "7"
    },
    {
      COD_ST: "7070",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-COM REDUCAO DE BASE DE CALCULO E COBRANCA DO ICMS POR ST",
      ORIGEM: "7"
    },
    {
      COD_ST: "7090",
      DESCRICAO: "ESTRANGEIRA - ADQUIRIDA NO MERCADO INTERNO, SEM SIMILAR NACIONAL-OUTRAS",
      ORIGEM: "7"
    }
  ];

  export default codigosSt;