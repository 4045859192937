import React, { useState } from "react";
import './produto.css'
import Navbar from "../navbar/navbar";
import FooterBar from "../footer/footerBar";



function ImagemProd(){
    const [codigoProduto, setCodigoProduto] = useState("");
    const [imagem, setImagem] = useState(null);
    const [imagemPreview, setImagemPreview] = useState(null);
    const [isModalOpenImage, setIsModalOpenImage] = useState(false);
  
    // Função para tratar a mudança no código do produto
    const handleCodigoChange = (event) => {
      setCodigoProduto(event.target.value);
    };
  
    // Função para tratar o upload de imagem
    const handleImagemChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setImagem(file);
        setImagemPreview(URL.createObjectURL(file)); // Cria uma URL temporária para visualização
      }
    };
  
    // Função para associar a imagem ao produto
    const associarImagemAoProduto = () => {
      if (!codigoProduto || !imagem) {
        alert("Por favor, insira um código de produto e selecione uma imagem.");
        return;
      }
      // Aqui você pode adicionar a lógica para salvar ou enviar os dados para um backend
      console.log("Produto:", codigoProduto);
      console.log("Imagem associada:", imagem.name);
      alert(`Imagem associada ao produto ${codigoProduto}!`);
    };
  
    return  (
        <div>
            <Navbar/>
        <div id="associar-imagem-produto" className="App">
            
          <h1>Associar Imagem ao Código do Produto</h1>
          <div>
            <label htmlFor="codigo-produto">Código do Produto:</label>
            <input
              type="text"
              id="codigo-produto"
              value={codigoProduto}
              onChange={handleCodigoChange}
              placeholder="Digite o código do produto"
            />
          </div>
    
          <div>
            <label htmlFor="upload-imagem">Imagem do Produto:</label>
            <input
              type="file"
              id="upload-imagem"
              accept="image/*"
              onChange={handleImagemChange}
            />
          </div>
    
          {imagemPreview && (
            <div>
              <h3>Pré-visualização da Imagem:</h3>
              <img onClick={() => setIsModalOpenImage(true)} id="imagem-preview" src={imagemPreview} alt="Imagem do produto" width={200} />
            
            </div>
          )}

          {isModalOpenImage && (
          <div
          className="modal fade show d-block"
          tabIndex="-1"
          onClick={() => setIsModalOpenImage(false)} // Fecha o modal ao clicar no fundo
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-0">
                {/* Exibindo a imagem expandida */}
                <img
                  src={imagemPreview}
                  alt="Imagem Expandida"
                  className="img-fluid"
                  style={{ width: '100%' }} // Tamanho total para a imagem expandida
                />
              </div>
            </div>
          </div>
        </div>
             )}
    
          <button id="associar-btn" onClick={associarImagemAoProduto}>
            Associar Imagem ao Produto
          </button>
        </div>
        <FooterBar/>
        </div>
      );
  
};

export default ImagemProd;