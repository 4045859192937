import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar/navbar'; 
import { api } from '../../../services/api';
import './finalizadora.css'
import {vhostV1} from '../../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import BuscaVazaia from '../../components/buscavazia/buscaVazia';
import Loading from '../../components/loading/Loading';
import finalizadoraPDF from '../../reports/finalizadora/listafinalizadora';
import customizeScrollbar from "customsb";
import FooterBar from '../../footer/footerBar';


function Finalizadora() {

   const [descricao, setDescricao] =useState('');
   const [tef, setTef] = useState(0);
   const [conta_bancaria, setConta_bancaria] = useState('');
   const [prazo, setPrazo] = useState(0);
   const [desconto, setDesconto] = useState(0);
   const [nr_parcelas, setNr_parcelas] = useState(0);
   const [tab_preco,setTab_preco] = useState(0);
   const [juros, setJuros] = useState(0);
   const [vlr_minimo_op, setValor_minimo_op] = useState(0);
   const [duplicata, setDuplicata] = useState("F");
   const [cartao, setCartao] = useState(0);
   const [sintetico, setSintetico] = useState(0);
   const [analitico, setAnalitico] = useState(0);
   const [desc_imp, setDesc_imp] = useState('dinheiro');
   const [permit_troco, setPermit_troco] = useState('N');
   const [cod_cedente, setCod_cedente]= useState(0);

   const [busca, setBusca] = useState('');
   const [finalizadora, setFinalizadora] = useState([]);
   const [sResult, setSresult] = useState(0);
   const [load, setLoad] = useState(false);
   const [texto, setTexto] = useState("");
   const [confirmaFinalizadora, setConfirmaFinalizadora] = useState('');
   const [confirmacao, setConfirmacao] = useState(false);


   let config = {
    headers: {
        'Authorization': localStorage.getItem("token")
    }
   }


    // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

   //buscar finalizadora
   useEffect(function(){
    
    api.get(vhostV1('webcash/finalizadora/ListaFinalizadoras'), config)
      .then(function (response) {
          if (response && response.data && response.data.dados){
          setFinalizadora(response.data.dados); 
          console.log(response.data.dados)
          setSresult(1);
        }else{
          setSresult(0);
        }       
      })
      .catch(function (error) {
        console.log(error);
                
      });

      
  },[])

  const finalizadorasFiltradas = finalizadora.filter((item) =>
    item.DESCRICAO.toLowerCase().includes(busca.toLowerCase())
  );

  

  function deleteFinalizadora(id) {
    api.post(vhostV1('webcash/finalizadora/deletaFinalizadora'),{
      loja: localStorage.getItem('loja'),
      empresa: 0 ,
      finalizadora: id
    },config)
    .then(function(response) {
      setConfirmacao(false);
      window.location.reload();

      
    }).catch(function(error){
      console.log(error);
      setConfirmacao(false);
      
    })
    
  }

  function confirmFinalizadora(id) {
    setConfirmaFinalizadora(id);
    setConfirmacao(true);
    setBusca('');
    
  }






   return <div>
       <Navbar/>
           <div className="container-fluid titulo offset-lg-1 col-lg-10 mb-5">
            <h1>Lista Finalizadoras</h1>

            <hr />

            <div className="row ">
          <div className="col-4 d-flex">
            <Link to='/app/novoFinalizadora/-1/1' className="btn btn-primary btn-cli me-2 custom-btn"  type="button"><i className="fas fa-plus"></i> Finalizadora</Link>
            <button onClick={(e) => finalizadoraPDF(finalizadorasFiltradas)} className="btn btn-danger btn-cli" type="button" id="button-addon2"><i className="bi bi-printer-fill"></i> Imprimir </button> 
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>


            { load === true?<Loading/>:null}
        { sResult === 1? 
         <table id='table-finalizadora' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Cod Finalizadora</th>
                <th scope="col">Descricao</th>
                <th scope="col">Tef</th>
                <th scope="col">Duplicata</th>
                <th scope="col">Prazo</th>
                {/* <th scope="col">Desconto</th> */}
                <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              { finalizadorasFiltradas.map((finalizadora)=> {
                return <tr key={finalizadora.COD_FINALIZADORA}>
                    <td>{finalizadora.COD_FINALIZADORA}</td>
                    <td>{finalizadora.DESCRICAO}</td>             
                    <td>{finalizadora.TEF}</td>
                    <td>{finalizadora.DUPLICATA}</td>
                    <td>{finalizadora.PRAZO}</td>
                    {/* <td>{finalizadora.DESCONTO}</td> */}
                    <td>
                    <div id="table-prod" className="dropdown">
                    <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Opções
                    </button>
                      <ul className='dropdown-menu'>
                        <li>
                        <Link to ={'/app/novoFinalizadora/' + finalizadora.COD_FINALIZADORA +'/0'} className="dropdown-item"> <i class="bi bi-eye-fill"></i>Vizualizar</Link>  
                        </li>
                        <li>
                        <Link to={'/app/novoFinalizadora/' + finalizadora.COD_FINALIZADORA+'/2'} className="dropdown-item"><i className="bi bi-pen-fill"></i>Editar</Link>
                        </li>
                        <li>
                        <Link  value={finalizadora.COD_FINALIZADORA} onClick={()=>(confirmFinalizadora(finalizadora.COD_FINALIZADORA))} className="dropdown-item"><i className="bi bi-trash-fill"></i>Excluir</Link>
                        </li>
                      </ul>
                  </div>
                   
                    
                    </td>
                    
                </tr>
                } )}
            </tbody>
          </table>
          :<BuscaVazaia/>}
 
                    
                    
                    
{
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteFinalizadora(confirmaFinalizadora)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir este Item?
            </SweetAlert>:null

        }

           </div>
           <FooterBar/> 
   </div>



}

export default Finalizadora;