import Modal from 'react-modal/lib/components/Modal';
import './nfe.css';
import { useEffect } from 'react';
import api from '../../services/api';
import { vhostV1 } from '../funcoes';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import CardNfe from './cardNfe';
import '../pedidos/pedidos.css'


function ModalPagamento(props){

    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }
      
    
      const [finalizadoras,setFinalizadoras] = useState([]);
      const [defaultVlrPago,setDefaultVlrPago] = useState(0);
      const [vlrPago,setVlrPago] = useState(0);
      const [finalizadora,setFinalizadora] = useState();
      const [desconto, setDesconto] = useState(0);
    
    // useEffect(()=>{
    //   api.post(vhostV1('webcash/finalizadora/ListaFinalizadoraResumida'), {
    //     empresa:0
    //   },config)
    //   .then(function (response) {
    //      setFinalizadoras(response.data.dados);    
         
                                   
    //   })
    //   .catch(function (error) {
    //      console.log(error);
    //   });
      
    // },[]);

    const formatCurrency = (value) => {
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    };
    


    useEffect(function(){
    
      api.get(vhostV1('webcash/finalizadora/ListaFinalizadoras'), config)
        .then(function (response) {
            if (response && response.data && response.data.dados){
              const dados = response.data.dados;
              
            
              const filtradas = dados.filter(forma => forma.COD_FINALIZADORA <= 50);
            setFinalizadoras(filtradas); 
            console.log(response.data.dados)
           
          }else{
            console.log(response);
            
          }       
        })
        .catch(function (error) {
          console.log(error);
                  
        });
  
        
    },[])


    function gravaRecebimento(){
                        
      api.post(vhostV1('webcash/vendas/gravarecebimento'),{
        nota:props.nota,
        valor:Number(vlrPago),
        finalizadora:finalizadora,
        responsavel:0,
        colaborador:localStorage.getItem('colaborador')
       },config)
       .then(function (response) {
         console.log(response); 
          
         props.onRequestClose();
        })
       .catch(function (error) {
        console.log(error);
      });
    }

    const sjson ={
      nota:props.nota,
      valor:Number(vlrPago),
      finalizadora:finalizadora,
      responsavel:0,
      colaborador:localStorage.getItem('colaborador')
     }

     console.log(sjson);
     


    
    
    
    
    
  return (
    <div>
        <Modal isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        overlayClassName="react-modal-overlay"
        ariaHideApp={false}
        className="pagamento-modal-content">
     
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Recebimento</h1>
          </div>
          <div className="modal-body">
          <div className="d-flex justify-content-between align-items-end p-3">
        <CardNfe title="Sub-total" style={{borderLeft: 'solid 5px #FFA500'}} value={formatCurrency(props.totalNota)} />
        <CardNfe title="Desconto" style={{borderLeft: 'solid 5px #dc3545'}}  value={formatCurrency(desconto)} />
        <CardNfe title="Valor" style={{borderLeft: 'solid 5px #28a745'}} value={formatCurrency(props.vlrPagar)} />
         </div>
            
            <br />
            <div className=" mb-3">
          
            <div className="col-md-12">
                <label htmlFor="inputState" className="form-label">Finalizadora</label>
                <select id="inputGrupo" onChange={(e) => setFinalizadora(e.target.value)} className="form-select" tabIndex="3">
                <option key={-1} >SEL. FINALIZADORA</option>
                { finalizadoras.map((pag)=> {
                    return <option key={pag.COD_FINALIZADORA} value={pag.COD_FINALIZADORA}> { pag.DESCRICAO}</option>          
                    } )}                    
                </select>
            </div>
            <div className="col-12">
            <label htmlFor="inputAddress" className="form-label">VALOR</label>
            <NumericFormat 
            className="form-control"
            id="inputVenda" 
            defaultValue={props.vlrPagar}
            value={defaultVlrPago.toLocaleString("pt-br", {style: "currency", currency: "BRL"})}
            tabIndex="9"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            prefix={"R$ "}
            onValueChange={(values, sourceInfo) => {
            setVlrPago(values.floatValue);
            }}  
             
                />

            </div>       
            
            </div>
             
            <br />
           
          </div>
          <div className="modal-footer d-flex justify-content-between">
          <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button> 
          <button className='btn btn-primary botao-fechar' onClick={gravaRecebimento}>Adicionar</button>
         
           
          </div>
        </div>
      </div>
    
        {/* <div className="container-fluid">
        <div className="row">
        <h4>Finalizadora</h4> 
    
        <hr />        
        </div> 
        <div className="d-flex justify-content-between align-items-end p-3">
        <CardNfe title="Sub-total" style={{borderLeft: 'solid 5px #FFA500'}} value={formatCurrency(props.totalNota)} />
        <CardNfe title="Desconto" style={{borderLeft: 'solid 5px #dc3545'}}  value={formatCurrency(desconto)} />
        <CardNfe title="Valor" style={{borderLeft: 'solid 5px #28a745'}} value={formatCurrency(props.vlrPagar)} />
        </div>
         <div className=" mb-3">
          
            <div className="col-md-12">
                <label htmlFor="inputState" className="form-label">Finalizadora</label>
                <select id="inputGrupo" onChange={(e) => setFinalizadora(e.target.value)} className="form-select" tabIndex="3">
                <option key={-1} >SEL. FINALIZADORA</option>
                { finalizadoras.map((pag)=> {
                    return <option key={pag.COD_FINALIZADORA} value={pag.COD_FINALIZADORA}> { pag.DESCRICAO}</option>          
                    } )}                    
                </select>
            </div>
            <div className="col-12">
            <label htmlFor="inputAddress" className="form-label">VALOR</label>
            <NumericFormat className="form-control"
                        id="inputVenda" 
                        value={defaultVlrPago.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                        tabIndex="9"
                        allowLeadingZeros
                        decimalSeparator=','
                        thousandSeparator='.'
                        decimalScale={2}
                        prefix='R$ '
                        onValueChange={(values,suorceInfo)=>{
                          setVlrPago(values.value);
                        }} 
                        
                        />
            </div>       
            
            </div>
        </div>

        <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button>
        <button className='btn btn-primary botao-fechar' onClick={gravaRecebimento}>Adicionar</button> */}

        </Modal>
            </div>
        )
}

export default ModalPagamento