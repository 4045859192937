import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import { useEffect } from "react";
import './produto.css'
import { vhostV1 } from "../funcoes";
import BuscaVazaia from "../components/buscavazia/buscaVazia";
import { NumericFormat } from 'react-number-format';
import SweetAlert from "react-bootstrap-sweetalert";
import FooterBar from "../footer/footerBar";
import semImagem from '../../images/semImagem.jpg'






function NovoProduto(){



    const inputs = document.querySelectorAll("input, select");

    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });
 



  const {cod} = useParams();
  const {tag} = useParams();

   const [cod_produto,setCod_produto]     = useState('');
   const [descricao,setDescricao]         = useState('');
   const [cod_grupo,setCod_grupo]         = useState(0);
   const [unidade,setUnidade]             = useState('UN');
   const [venda,setVenda]                 = useState(0);
   const [vendaDefault,setvendaDefault]   = useState(0);
   const [ncm,setNcm]                     = useState('');
   const [sucesso,setSucesso]             = useState('N')
   const [mensagem, setMensagem]          = useState('');
   const [grupos,setGrupos]               = useState([]);
   const [compra,setCompra]               = useState(0);
   const [compraDefault,setCompraDefault] = useState(0);
   const [st,setST]                       = useState('');
   const [icms_in,setIcms_in]             = useState(0);
   const [icms_out,setIcms_out]           = useState(0);
   const [cfop_in,setCfop_in]             = useState('');
   const [cfop_out,setCfop_out]           = useState('');
   const [cest,setCest]                   = useState('');
   const [cstpc,setCstpc]                 = useState('');
   const [cstpcEntrada,setCstpcEntrada]   = useState(0);
   const [pisCredito,setPisCredito]       = useState(0);
   const [cofinsCredito,setCofinsCredito] = useState(0);
   const [pisDebito,setpisDebito]         = useState(0);
   const [cofinsDebito,setCofinsDebito]   = useState(0);
   const [natReceita,setNatReceita]       = useState('');
   const [seqNcm,setSeqNcm]               = useState('');
   const [tipoProd,setTipoProd]           = useState('0');
   const [sabores,setSabores]             = useState(0);
   const [estoque,setEstoque]             = useState(0);
   const [adicionais,setAdicionais]       = useState(0);
   const [markup,setMarkup]               = useState(0);
   const [markupDefault,setMarkupDefalt]  = useState(0);
   const [vendaMarkup,setVendaMarkup]     = useState(0);
   const [localizacao,setLocalizacao]     = useState(0);
   const [erroValidacao, setErrovalidacao] = useState(false);
   const [msgErro, setmsgErro] = useState('');
   const [resultImagem, setResultImagem] = useState(0);
   const [imagemProduto, setImagemProduto] = useState('');

   const [buscaNcm,setBuscaNcm]= useState('');
   const [Ncms,setNcms]        = useState([]);
   const [sResult,setSresult]  = useState(0);
   const [verificaProd, setVerificaProd] = useState('1');

   const [image, setImage] = useState(null);
    const [base64, setBase64] = useState('');
    const [isModalOpenImage, setIsModalOpenImage] = useState(false);
  
    
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token"),
      'Content-Type': 'application/json'
    },
    'timeout': 10000
  }

  
    
  
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64(reader.result); 
        };
        reader.readAsDataURL(file); 
        setImage(URL.createObjectURL(file)); 
      }
    };
    
  
  //buscar Grupos
  useEffect(function(){
      
    api.post(vhostV1('webcash/grupo/listar'), {
        loja: 11,
        empresa: 0,
        busca: ''
      },config)
      .then(function (response) {
        setGrupos(response.data.dados); 
        
      })
      .catch(function (error) {
        console.log(error+'erro ao listar Grupos');
                
      });

      
  },[])

  function buscaIten(){
    api.post(vhostV1('webcash/produto/retornaproduto/'+cod_produto), {
      loja: localStorage.getItem('loja'),
      empresa: 0         
    },config)
    .then(function (response) { 
      
     if(response && response.data && response.data.dados){
      setVerificaProd(response.data.dados.COD_PRODUTO);
      console.log(response)

      
      
       }
     else{
       console.log("não busquei")
     }  
      
     })
    .catch(function (error) {
     
      console.log(error);
              
    });
     };

     useEffect(function(){
      const loja = localStorage.getItem('loja');

      console.log(loja);
      console.log(cod_produto);
      
      
      
      api.get(vhostV1(`webcash/produto/retornafoto/${loja}/${cod_produto}`),config)
        .then(function (response) {
         console.log(response);
         if(response.data.result === 0){
          setImagemProduto(semImagem)
         }else{
          const base64Pattern = /^data:image\/[a-z]+;base64,/;
          if (base64Pattern.test(response.data.dados)) {
              setImagemProduto(response.data.dados); // Define a imagem base64
          } else {
              setImagemProduto(semImagem); // Caso o base64 não seja válido, usa a imagem padrão
          }
         }
         
          
        })
        .catch(function (error) {
          console.log('erro na imagem Produto');
                  
        });
  
        
    },[cod_produto])


  //buscar Produto
  useEffect(function(){  
      
    api.post(vhostV1('webcash/produto/Pesquisa/'+cod), {
        loja: localStorage.getItem('loja'),
        empresa: 0        
      },config)
      .then(function (response) {
        console.log(response.data.dados);
        
        if (response.data.result === 1){
         setCod_produto(response.data.dados.COD_PRODUTO);  
         setDescricao(response.data.dados.DESCRICAO);  
         setNcm(response.data.dados.COD_NCM);  
         setVenda(response.data.dados.VENDA);  
         setVendaMarkup(response.data.dados.VENDA_MARKUP);  
         setMarkup(response.data.dados.MARKUP);  
         setMarkupDefalt(response.data.dados.MARKUP);  
         setvendaDefault(response.data.dados.VENDA);  
         setUnidade(response.data.dados.UNIDADE);  
         setCod_grupo(response.data.dados.COD_GRUPO);  
         setCompra(response.data.dados.COMPRA);  
         setCompraDefault(response.data.dados.COMPRA);  
         setST(response.data.dados.ST);  
         setCest(response.data.dados.CEST);  
         setIcms_in(response.data.dados.ICMS_IN);  
         setIcms_out(response.data.dados.ICMS_OUT);  
         setCfop_in(response.data.dados.CFOP_IN);  
         setCfop_out(response.data.dados.CFOP_OUT);  
         setCstpc(response.data.dados.CSTPC);  
         setCstpcEntrada(response.data.dados.CSTPC_ENTRADA);  
         setPisCredito(Number(response.data.dados.ALI_PIS_CREDITO));  
         setpisDebito(Number(response.data.dados.ALI_PIS_DEBITO));  
         setCofinsCredito(Number(response.data.dados.ALI_COFINS_CREDITO));  
         setCofinsDebito(Number(response.data.dados.ALI_COFINS_DEBITO));  
         setNatReceita(response.data.dados.COD_RECEITA_PIS);
         setEstoque(response.data.dados.ESTOQUE);
         setLocalizacao(response.data.dados.LOCALIZACAO);
         setTipoProd(response.data.dados.TIPO_PROD); };
         

                               
      });

      
  },[])

  function listaNcm(e){
    e.preventDefault();
    api.post(vhostV1('webcash/produto/pesquisa/ncm'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        busca: buscaNcm
      },config)
      .then(function (response) {
        console.log(response.data)
         setSresult(response.data.result);
         setNcms(response.data.dados); 
         
        
                 
      })
      .catch(function (error) {
       
        console.log(error);

                
      });
    }

    


    function pesquisaNcm(e){
    
      api.post(vhostV1('webcash/produto/pesquisa/prodncm/'+e), {
          loja: localStorage.getItem('loja'),
          empresa: 0        
        },config)
        .then(function (response) {
            console.log(response.data) 
            setNcm(response.data.dados.NCM);
            setST(response.data.dados.ST);
            setIcms_in(response.data.dados.ICMS_IN);
            setIcms_out(response.data.dados.ICMS_OUT);
            setCfop_in(response.data.dados.CFOP_IN);
            setCfop_out(response.data.dados.CFOP_OUT);
            setCest(response.data.dados.CEST);                   
            setCstpc(response.data.dados.CSTPC);
            setCstpcEntrada(response.data.dados.CSTPC_ENTRADA);
            setPisCredito(response.data.dados.PERCPISCRED);
            setCofinsCredito(response.data.dados.PERCCOFINSCRED);
            setpisDebito(response.data.dados.PERCPISCRED);
            setCofinsDebito(response.data.dados.PERCCOFINSCRED);
            setNatReceita(response.data.dados.NAT_RECEITA);   
                     
        });
  }

  function calculaVenda(){
     let mk = (compra * markup)/100;
     let vendaSugerida = Number(compra) + Number(mk) 
     setVendaMarkup(vendaSugerida);
  }

  useEffect(function(){
    if(cod_produto === verificaProd){
      {setmsgErro('CÓDIGO DE PRODUTO JÁ CADASTRADO');
      setErrovalidacao(true)}
      return
    }
  },[verificaProd])

  




  function salvaProduto(e){
    e.preventDefault();
    if(cod_produto.length === 0 ){  
      {setmsgErro("CÓDIGO DO PRODUTO NÃO PODE ESTAR VAZIO");
          setErrovalidacao(true)}
      return    
  } else if(descricao.length === 0){  
    {setmsgErro("DESCRIÇÃO DO PRODUTO NÃO PODE ESTAR VAZIO");
        setErrovalidacao(true)}
    return    
}   else if(ncm.length === 0){  
  {setmsgErro("NCM NÃO PODE ESTAR VAZIO");
      setErrovalidacao(true)}
    return
}    else if(venda.length === 0 || venda === 0){  
  {setmsgErro("VENDA NÃO PODE ESTAR VAZIO/ZERADO");
      setErrovalidacao(true)}
    return
} else  if(cod_produto === verificaProd){
  {setmsgErro('CÓDIGO DE PRODUTO JÁ CADASTRADO');
  setErrovalidacao(true)}
  return
}
  
  else{
        api.post(vhostV1('webcash/produto/cadastro'),{
            loja:Number(localStorage.getItem('loja')),
            EMPRESA:0,
            COD_INTERNO:cod,
            COD_PRODUTO:cod_produto,
            DESCRICAO:descricao,
            COD_GRUPO:Number(cod_grupo),
            UNIDADE:unidade,
            ESTOQUE:estoque,
            MARKUP:Number(markup),
            VENDA_MARKUP:vendaMarkup,
            VENDA:Number(venda),
            COMPRA:Number(compra),
            COLABORADOR:localStorage.getItem('colaborador'),
            NCM:ncm,   
            ST:st,
            ICMS_IN:icms_in,
            ICMS_OUT:icms_out,
            CFOP_IN:cfop_in,
            CFOP_OUT:cfop_out,
            CEST:cest,
            CSTPC:cstpc,
            CSTPC_ENTRADA:cstpcEntrada,
            ALI_COFINS_CREDITO:Number(cofinsCredito),
            ALI_COFINS_DEBITO:Number(cofinsDebito),
            ALI_PIS_CREDITO:Number(pisCredito),
            ALI_PIS_DEBITO:Number(pisDebito),
            SABORES:sabores,
            ADICIONAIS:adicionais,
            LOCALIZACAO:localizacao,
            QTD_EMBALAGEM: 1,
            TIPO: tipoProd
           
        },config)
        .then(function(response){
            if(response.data.result === 1)
            { setMensagem('');
              setSucesso('S');
            }else{
              console.log(response.data.msg);
              setSucesso('N');
            }            
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
            console.log(erro)
        });

   } }

     
    


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-1 col-lg-10">
               <h2>Cadastro de Produto</h2>                   
               <hr />
                <div className="conteiner-fluid">
                <form id="table-servico" className="row tela" >
               
      <div className="row align-items-center mb-3">
        
        <div className="col-2">
          <label htmlFor="inputCodigo" className="form-label">CÓDIGO</label>
          <input
            type="text"
            value={cod_produto}
            onChange={(e) => setCod_produto(e.target.value)}
            className="form-control"
            id="inputCodigo"
            tabIndex="1"
            required
            autoComplete="off"
          />
        </div>

       
        <div className="col-8">
          <label htmlFor="inputDescricao" className="form-label">DESCRIÇÃO</label>
          <input
            type="text"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            className="form-control"
            id="inputDescricao"
            tabIndex="2"
            required
            autoComplete="off"
          />
        </div>

        <div className="col-2 text-center">
          {/* Exibindo a miniatura da imagem fixa */}
          <div className="mt-2">
            <img
              src={imagemProduto} // Aqui você usa a imagem fixa importada
              alt="Imagem"
              className="img-thumbnail"
              style={{ maxWidth: '100px', height: '100px', cursor: 'pointer' }} // Tamanho fixo para a miniatura
              onClick={() => setIsModalOpenImage(true)} // Ao clicar, abre o modal
            />
          </div>
        </div>
      </div>

      {/* Modal para imagem expandida */}
      {isModalOpenImage && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          onClick={() => setIsModalOpenImage(false)} // Fecha o modal ao clicar no fundo
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body p-0">
                {/* Exibindo a imagem expandida */}
                <img
                  src={imagemProduto}
                  alt="Imagem Expandida"
                  className="img-fluid"
                  style={{ width: '100%' }} // Tamanho total para a imagem expandida
                />
              </div>
            </div>
          </div>
        </div>
      )}
    
    

                   
                   <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">GRUPO</label>
                       <select id="inputGrupo" value={cod_grupo} onChange={(e)=> setCod_grupo(e.target.value)} className="form-select" tabIndex="3">
                        <option value={0}>SELECIONE</option>
                       { grupos.map((grupo)=> {
                        return <option key={grupo.cod_grupo} value={grupo.cod_grupo}>{grupo.cod_grupo} - { grupo.descricao}</option>          
                        } )}
                        
                       </select>
                   </div>

                   <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">UNIDADE</label>
                       <select id="inputUnidade" value={unidade} onChange={(e)=> setUnidade(e.target.value)} className="form-select" tabIndex="4" >
                           <option value={'UN'}>UN</option>
                           <option value={'KG'}>KG</option>
                           <option value={'CX'}>CX</option>                    
                           <option value={'LT'}>LT</option>                    
                       </select>
                   </div>
                      
                   
                    


                       <div className="col-md-3">
                       <label htmlFor="inputCity" className="form-label">NCM</label>
                        <div className="input-group mb-3">
                           <input type="text"  value={ncm}  onChange={(e)=> setNcm(e.target.value)} className="form-control" id="inputNcm" tabIndex="5" readOnly required/>
                            {tag != 0?
                            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            pesquisar
                            </button>
                            :null}
                        </div>

                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                         <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Pesquisa NCM</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div >
                                <div className="input-group mb-3">
                                  <input onChange={(e) => setBuscaNcm(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
                                  <button onClick={listaNcm} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                                </div>
                                {sResult === 1?
                                <table id="table-produto" className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Descricao</th>
                                    <th scope="col">ST</th>
                                    <th scope="col">ICMS</th>
                                    <th scope="col">AÇÃO</th>

                                </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                { Ncms.map((ncm)=> {
                                    return <tr key={ncm.SEQ}>
                                        <td>{ncm.NCM}</td>          
                                        <td>{ncm.PRODUTO}</td>
                                        <td>{ncm.ST}</td>
                                        <td>{Number(ncm.ICMS_IN).toFixed(2)}</td>
                                        <td>
                                          <a href="" onClick={()=> pesquisaNcm(ncm.SEQ)} value={ncm.SEQ} >
                                          <i className="bi bi-check-lg" data-bs-dismiss="modal"></i>
                                          </a>                                        
                                        </td>
                                    </tr>
                                } )}  
                                </tbody>
                              </table>:<BuscaVazaia/>}
    

                                </div>
                            </div>
                            <div className="modal-footer">
                                
                            </div>
                            </div>
                        </div>
                        </div>
                        
                      </div>

                       
                      <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">TIPO PROD</label>
                        <select id="inputUnidade" value={tipoProd} onChange={(e)=> setTipoProd(e.target.value)} className="form-select" tabIndex="5" >
                           <option value={0}>VENDA</option>
                           <option value={1}>COMPOSTO</option>
                           <option value={2}>CONSUMO</option>
                           <option value={3}>MATERIA_PRIMA</option>
                           <option value={4}>MIX</option>
                           <option value={5}>CORTE</option>
                           
                       </select>
                      </div>

                      <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">IMPRESSORA</label>
                        <select id="inputUnidade" value={localizacao} onChange={(e)=> setLocalizacao(e.target.value)} className="form-select" tabIndex="5" >
                           <option value={0}>SELECIONE</option>
                           <option value={1}>COZINHA</option>
                           <option value={2}>PRODUCAO</option>
                           <option value={3}>COZINHA2</option>
                                                     
                       </select>
                      </div>

                      <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">ESTOQUE</label>                       
                       <NumericFormat className="form-control"
                                      id="inputMarkup" 
                                      value={estoque.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="7"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={3}
                                      readOnly={true}
                                      
                                                    
                       
                       
                       />
                   </div>  

                      <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">MARKUP</label>                       
                       <NumericFormat className="form-control"
                                      id="inputMarkup" 
                                      value={markupDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="7"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      suffix=" %"
                                      onValueChange={(values,suorceInfo)=>{
                                        setMarkup(values.value);
                                      }}              
                       
                       
                       />
                   </div>                   

                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">COMPRA</label>
                       <NumericFormat className="form-control"
                                      id="inputCompra" 
                                      value={compraDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="8"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      onBlur={calculaVenda}
                                      decimalScale={2}
                                      prefix="R$ "
                                      onValueChange={(values,suorceInfo)=>{
                                        setCompra(values.value);
                                      }} 
                                 />     
                   </div>            

                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">PREÇO SUGERIDO</label>
                       <NumericFormat className="form-control"
                                      id="inputsugestao" 
                                      value={vendaMarkup.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="50"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      
                                 />     
                   </div>               
       
                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">VALOR</label>
                       <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={vendaDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      required
                                      prefix="R$ "
                                      onValueChange={(values,suorceInfo)=>{
                                        setVenda(values.value);
                                       
                                        
                                      }} 
                                 />  
                   </div>  


                    <h5>impostos</h5>  
                    <hr />
                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">ST</label>
                       <input type="text" value={st} onChange={(e)=> setST(e.target.value)} className="form-control" id="inputST" tabIndex="7" disabled/>
                    </div>

                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">ICMS DENTRO</label>
                       <input type="text" value={Number(icms_in).toFixed(2)} onChange={(e)=> setIcms_in(e.target.value)} className="form-control" id="inputICMSIN" tabIndex="8" disabled/>
                    </div>

                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">ICMS FORA</label>
                       <input type="text" value={Number(icms_out).toFixed(2)} onChange={(e)=> setIcms_out(e.target.value)} className="form-control" id="inputICMSOUT" tabIndex="9" disabled/>
                    </div>

                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">CFOP DENTRO</label>
                       <input type="text" value={cfop_in} onChange={(e)=> setCfop_in(e.target.value)} className="form-control" id="inputCFOPIN" tabIndex="10" disabled/>
                    </div>

                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">CFOP FORA</label>
                       <input type="text" value={cfop_out} onChange={(e)=> setCfop_out(e.target.value)} className="form-control" id="inputCFOPOUT" tabIndex="11" disabled/>
                    </div>

                    <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">CEST</label>
                       <input type="text" value={cest} onChange={(e)=> setCest(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>

                    <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">CSTPC</label>
                       <input type="text" value={cstpc} onChange={(e)=> setCstpc(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>
                       
                    <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">ALI. PIS</label>
                       <input type="text" value={pisDebito} onChange={(e)=> setpisDebito(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>

                     <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">ALI. COFINS</label>
                       <input type="text" value={cofinsDebito} onChange={(e)=> setCofinsDebito(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>

                     <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">NAT. RECEITA</label>
                       <input type="text" value={natReceita} onChange={(e)=> setNatReceita(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>   
                   
                    <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">CSTPC ENTRADA</label>
                       <input type="text" value={cstpcEntrada} onChange={(e)=> setCstpcEntrada(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>
                       
                    <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">ALI. PIS</label>
                       <input type="text" value={pisCredito} onChange={(e)=> setPisCredito(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>

                     <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">ALI. COFINS</label>
                       <input type="text" value={cofinsCredito} onChange={(e)=> setCofinsCredito(e.target.value)} className="form-control" id="inputCEST" tabIndex="12" disabled/>
                    </div>

                   {  tipoProd === '4'?
                    <div className="row col-12 ">
                       <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">VARIAÇÕES</label>
                       <select id="inputGrupo" onChange={(e)=> setSabores(e.target.value.substring(1,3))} className="form-select" tabIndex="4">
                       { grupos.map((grupo)=> {
                        return <option key={grupo.cod_grupo}> { ("000" + grupo.cod_grupo).slice(-3) +' - ' +grupo.descricao}</option>          
                        } )}
                        
                       </select>
                    </div>

                    <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">ADICIONAIS</label>
                       <select id="inputGrupo" onChange={(e)=> setAdicionais(e.target.value.substring(1,3))} className="form-select" tabIndex="4">
                       { grupos.map((grupo)=> {
                        return <option key={grupo.cod_grupo}> { ("000" + grupo.cod_grupo).slice(-3) +' - ' +grupo.descricao}</option>          
                        } )}
                        
                       </select>
                   </div>

                    </div>: <div className="row"></div>
                    }
                                                   
                   
                   <div className="col-12 mb-5">
                   <hr />
                       <Link to="/app/produto" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                       {tag != '0'?(
                                  <button type="submit" onClick={salvaProduto} className="btn btn-primary butao" tabIndex="13">Salvar</button>
                                  ):null}

                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to='/app/produto' /> : null}
       
                   </form> 
            </div>
            {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
        </div>  
        <FooterBar/>
   </div>

}



export default NovoProduto;