import React, { useState, useEffect } from 'react';
import { Link,Navigate,useParams} from 'react-router-dom';
import Navbar from '../navbar/navbar';
import './cadastro.css'
import { api } from '../../services/api';
import { vhostV1 } from '../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CSVLink } from 'react-csv';
import ListaCadastroPdf from '../reports/cadastro/listacadastro';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import customizeScrollbar from "customsb";
import SRI_pagination from '../components/pagination/pagination';
import FooterBar from '../footer/footerBar';





function Cadastro(props) {
     
    // paramentro do tipo do cliente
    const {tp} = useParams();
     
    

     const [sResult,setSresult] = useState(0);
     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [cadastros,setCadastro] = useState([]);
     const [refresh,setRefresh] = useState(0);
     const [navegar, setNavegar] = useState(false);
     const [contas, setContas] = useState([]);
     const [msgErro, setmsgErro] = useState('');
     const [erroValidacao, setErrovalidacao] = useState(false);
     
     const [confirmacao,setConfirmacao] = useState(false);
     const [confirmacaoId,setConfirmacaoID] = useState('');
     const [nPag,setnPag] = useState(1);
     const [totalPaginas,setTotalPaginas] = useState(0); 


      function deleteUser(id){
        api.post(vhostV1('webcash/cadastro/excluir'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          cod_cadastro: id
        },config)
        .then(function (response) {
          setConfirmacao(false);
          setNavegar(true);
                  
        })
        .catch(function (error) {
          console.log(error);
          setConfirmacao(false);
          
        });
      
     
    }

    function handlePageChange(value){
      if(value === "&laquo;" || value === '... '){
        setnPag(1);
      }else if (value === "&lsaquo;" ){
        if (nPag !== 1){
          setnPag(-1);
        }
      }else if(value === "&rsaquo;"){
        setnPag(+1);        
      }else if(value === "&raquo;" || value === ' ...' ){
        setnPag(totalPaginas);        
      }else{
        setnPag(value);
      }    
     
     }

         // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

function podeExcluirCadastro(id) {
  // Verificar se há produtos associados ao grupo
  const contasAssociadas = contas.some(conta => Number(conta.RESPONSAVEL) === Number(id));
  console.log(contasAssociadas);
  console.log(id);
  
  
  
  if (contasAssociadas) {
    console.log(`Não é possível excluir o cadastro com código ${id} porque existem contas associadas a ele.`);
    return false;
  }

  return true;
}

function confirmadeleteUser(id) {
  const podeExcluir = podeExcluirCadastro(id); // Chama a função e armazena o resultado

  if (!podeExcluir) { // Verifica se a exclusão não é permitida
    setmsgErro(`Não é possível excluir o cadastro com código ${id} porque existem contas associadas a ele.`);
    setErrovalidacao(true);
  } else {
    setConfirmacaoID(id);
    setConfirmacao(true);
    setBusca('');
  }
}

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  useEffect(function(){
    api.post(vhostV1('webcash/contas'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        busca: busca,
        tipo: '%',
        colaborador:0
      },config)
      .then(function (response) { 
        
         setSresult(response.data.result); 
         setContas(response.data.dados);
         console.log(response.data.dados);
                 
        }                               
      )
      .catch(function (error) {
        console.log(error);
                
      });

      


      
  },[])

  //buscar clientes
  useEffect(function(){

       api.post(vhostV1('webcash/cadastro'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          pagina: nPag,
          regPag: 10,
          tipo:tp,
          busca: busca
        },config)
        .then(function (response) {
          setSresult(response.data.result); 
          setCadastro(response.data.dados);
          console.log(response);
          
          setRefresh(true);
                                              
                   
        })
        .catch(function (error) {
         
          console.log(error);
          setRefresh(true);

                  
        });
  
        
    },[busca,refresh])


    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        {tp === 'c'?<h1>Cadastro de Cliente</h1>:<h1>Cadastro de Fornecedor</h1>}
        
        <hr />

        <div className="row">
          <div className="col-4">
            <Link id='botao-cadastro' to={'/app/novocadastro/-1/'+tp+'/'+'1'} className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Cadastrar</Link>
            <button id='botao-cadastro' className="btn btn-danger btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-printer-fill"></i> Relatório</button>
          </div>

          


          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de {tp === 'c'?'Clientes':'Fornecedor'}</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>ordenar por </h5> 
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Código
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" defaultChecked={false} name="flexRadioDefault" id="flexRadioDefault2" checked/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Alfabética
                    </label>
                  </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                  { sResult === 1?
                  <div>
                  <button type="button" onClick={(e) => ListaCadastroPdf(cadastros)} className="btn btn-primary btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  <CSVLink data={cadastros} className="btn btn-success" >Excel</CSVLink>
                  </div>
                  :null} 
                  

                </div>
              </div>
            </div>
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>   
        { sResult === 1?
        <>
         <table id='table-cliente' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Codigo</th>
                <th scope="col">Nome</th>
                <th scope="col">Cnpj / Cpf</th>
                <th scope="col">Endereço</th>
                <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {  
                 cadastros.map((cadastro)=> {
                  return <tr key={cadastro.COD_CADASTRO}>
                      <td>{cadastro.COD_CADASTRO}</td>          
                      <td>{cadastro.RAZAO}</td>
                      <td>{cadastro.CNPJ}</td> 
                      <td>{cadastro.ENDERECO}</td>                     
                      <td>
                      <div id="table-prod" className="dropdown">
                    <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Opções
                    </button>
                      <ul className='dropdown-menu'>
                        <li>
                        <Link to ={'/app/novocadastro/' + cadastro.COD_CADASTRO+'/'+tp+'/'+'0'} className="dropdown-item"> <i class="bi bi-eye-fill"></i>Vizualizar</Link>  
                        </li>
                        <li>
                        <Link to ={'/app/novocadastro/'+cadastro.COD_CADASTRO+'/'+tp+'/'+'2'} className="dropdown-item"><i className="bi bi-pen-fill"></i>Editar</Link>
                        </li>
                        <li>
                        <Link to ="#" onClick={() => confirmadeleteUser(cadastro.COD_CADASTRO)} className="dropdown-item"><i className="bi bi-trash-fill"></i>Excluir</Link>
                        </li>
                      </ul>
                  </div>
                      </td>
                  </tr>
              } )}  
            </tbody>
          </table>
          <div className="row"></div>
          <div className="row mt-5 mb-5">
            <SRI_pagination totalPage={totalPaginas} 
                            page={nPag} 
                            limit={10} 
                            siblings={1}
                            onPageChange={handlePageChange}/>
            </div>
          </>
         :<BuscaVazaia/>} 

{
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }

          {
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteUser(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir o cliente!
            </SweetAlert>:null
           
          }

    {navegar ? <Navigate to='/app/cadastro/c' /> && window.location.reload()  : null}
     
      </div> 
      <FooterBar/>     
    </div>
}


export default Cadastro;