import React, { useEffect, useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalSt.css'
import customizeScrollbar from 'customsb';
import codigosSt from '../nfe/codigosSt'; 
import BuscaVazaia from '../components/buscavazia/buscaVazia';

function ModalSt({ isOpen, onRequestClose, setCstIcms, tipoEmpresa }) {

    
    

    const [busca, setBusca] = useState('');
    const [codigosFiltrados, setCodigosFiltrados] = useState(codigosSt); 

   
    useEffect(() => {
        customizeScrollbar({
            scrollbarWidth: "10px",
            trackBackground: "#333",
            thumbBackground: "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
            thumbBorderRadius: "10px",
        });
    }, []);

    function filtraSt() {
        
        if (busca.trim() === '') {
            
            if (tipoEmpresa !== 2) {
                
                setCodigosFiltrados(codigosSt.filter(prod => prod.ORIGEM === '1'));
            } else {
                
                setCodigosFiltrados(codigosSt.filter(prod => prod.ORIGEM !== '1'));
            }
        } else {
           
            const resultadoFiltrado = codigosSt.filter((prod) => {
                const matchesBusca = 
                    prod.COD_ST.toLowerCase().includes(busca.toLowerCase()) ||
                    prod.DESCRICAO.toLowerCase().includes(busca.toLowerCase()) ||
                    prod.ORIGEM.toLowerCase().includes(busca.toLowerCase());
    
                const matchesTipoEmpresa = 
                    tipoEmpresa !== 2 ? prod.ORIGEM === '1' : prod.ORIGEM !== '1';
    
                return matchesBusca && matchesTipoEmpresa;
            });
    
            setCodigosFiltrados(resultadoFiltrado);
        }
    }
    

    function handelSt(prod) {
        setCstIcms(prod.COD_ST);
        onRequestClose();
    }

    return (
        <Modal isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            ariaHideApp={false}
            className="react-modal-content">
            <div className="container-fluid pesquisa-modal">
                <div className="row">
                    <h4>Pesquisa Situação Tributária - ICMS</h4>
                    <hr />
                    <div className="col-12">
                        <div className="input-group mb-3">
                            <input
                                onChange={(e) => setBusca(e.target.value)}
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar por nome"
                                aria-describedby="button-addon2"
                            />
                            <button
                                onClick={filtraSt}
                                className="btn btn-primary"
                                type="button"
                                id="button-addon2">
                                <i className="bi bi-search"></i> Pesquisar
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row produtos">
                    {codigosFiltrados.length > 0 ? (
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Código</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Origem</th>
                                    <th scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {codigosFiltrados.map((prod) => {
                                    return (
                                        <tr key={prod.COD_ST}>
                                            <td>{prod.COD_ST}</td>
                                            <td>{prod.DESCRICAO}</td>
                                            <td>{prod.ORIGEM}</td>
                                            <td>
                                                <a onClick={() => handelSt(prod)}>
                                                    <i className="bi bi-file-arrow-down-fill"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <BuscaVazaia />
                    )}
                </div>

                <div className="row align-items-end">
                    <button className='btn btn-danger botao-fechar' onClick={onRequestClose}>Cancelar</button>
                </div>

            </div>
        </Modal>
    );
}

export default ModalSt;
