import React, { useEffect, useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalCfop.css'
import BuscaVazaia from '../buscavazia/buscaVazia';
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import customizeScrollbar from 'customsb';


function ModalCfop({isOpen, onRequestClose, setCfop, setNaturezaOp, uf, ufEmpresa, finalidadeEmissao }){

    let config = {
        headers: {
          'Authorization': localStorage.getItem("token")
        }
      }
    
      useEffect(() => {
        // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
        customizeScrollbar({
          scrollbarWidth: "10px",
          trackBackground: "#333",
          thumbBackground:
            "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
          thumbBorderRadius: "10px",
        });
      }, []);

      const [cfopFiltrado, setCfopFiltrado] = useState([]);
      const [busca, setBusca] = useState('');


      function filtraCfop() {
        api.post(vhostV1('webcash/produto/pesquisa/cfop'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            busca: busca
        }, config)
        .then(function (response) {
            if (response && response.data && response.data.dados) {
                console.log(response.data.dados);
                let dadosFiltrados = response.data.dados;
    
               
                if (Number(finalidadeEmissao) === 4) {
                    dadosFiltrados = dadosFiltrados.filter(item => {
                        const cfop = String(item.CFOP); 
                        return (uf === ufEmpresa && cfop.startsWith('5') && item.DESCRICAO.startsWith("DEVOLUÇÃO")) ||
                               (uf !== ufEmpresa && cfop.startsWith('6') && item.DESCRICAO.startsWith("DEVOLUÇÃO"));
                    });
                } else {
                    dadosFiltrados = dadosFiltrados.filter(item => {
                        const cfop = String(item.CFOP); 
                        return (uf === ufEmpresa && cfop.startsWith('5')) ||
                               (uf !== ufEmpresa && cfop.startsWith('6'));
                    });
                }
    
                setCfopFiltrado(dadosFiltrados);
            } else {
                setCfopFiltrado([]);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    function cleanModal(){
        setCfop('');
        setNaturezaOp('');
        onRequestClose();
    }
    

    function handelProduto(item){
        setCfop(item.CFOP)
        setNaturezaOp(item.DESCRICAO);
        onRequestClose();
   
     }

     return <Modal isOpen={isOpen}
     onRequestClose={onRequestClose}
     overlayClassName="react-modal-overlay"
     ariaHideApp={false}
     className="react-modal-content">
<div className="container-fluid pesquisa-modal">
 <div className="row">
     <h4>Pesquisa CFOP</h4>              
     <hr />
     
     <div className="col-12">
     <div className="input-group mb-3">
     <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome ou CFOP" aria-describedby="button-addon2" />
     <button onClick={(e) => filtraCfop(e.target.value)} value={busca}  className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
     
    
     </div>
     </div>       
 </div> 

<div className="row produtos">

{ cfopFiltrado.length > 0?
                     <table className="table table-hover table-striped">
                     <thead>
                     <tr>
                         <th scope="col">CFOP</th>
                         <th scope="col">Natureza da Operação</th>
                         <th scope="col">Ação</th>
                         </tr>
                     </thead>
                     <tbody className="table-group-divider">
                     { cfopFiltrado.map((item)=> {
                         return <tr key={item.CFOP}>
                             <td>{item.CFOP}</td>          
                             <td>{item.DESCRICAO}</td>
                            
                             <td>
                               <a  onClick={(e) => handelProduto(item)}>
                               <i class="bi bi-file-arrow-down-fill"></i>
                               </a>                                        
                             </td>
                         </tr>
                     } )}  
                     </tbody>
                 </table>:<BuscaVazaia/>}

</div>
 
<div className="row align-items-end">
     <button className='btn btn-danger botao-fechar'onClick={cleanModal}>Cancelar</button>
 </div>
 
</div>



</Modal>



}

export default ModalCfop;