import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class EtiquetaPDF extends React.Component {
  generatePDF = () => {
    const { produto } = this.props;
    const barcodeOptions = {
      format: 'CODE128',
      displayValue: false,
      lineColor: '#000',
      width: 1,
      height: 20
    };

    function abreviarString(str, maxLength) {
      if (str.length > maxLength) {
        return str.slice(0, maxLength - 1) + '.';
      }
      return str;
    }

    const descricao = abreviarString(produto.DESCRICAO, 35);

    const canvas = document.createElement('canvas');
    JsBarcode(canvas, produto.COD_PRODUTO, barcodeOptions);
    const barcodeDataURL = canvas.toDataURL('image/png');

    const documentDefinition = {
      pageSize: { width: 255, height: 85 }, // 90mm x 30mm
      pageMargins: [10, 5, 10, 5], // Margens ajustadas
      content: [
        {
          text: descricao,
          fontSize: 10,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 5] // Espaçamento abaixo do texto
        },
        {
          columns: [
            {
              width: '60%',
              stack: [
                {
                  image: barcodeDataURL,
                  width: 120,
                  height: 40,
                  alignment: 'center',
                  margin: [0, 0, 0, 5]
                },
                {
                  text: produto.COD_PRODUTO,
                  fontSize: 10,
                  alignment: 'center',
                  margin: [-10, -10, 0, 0]
                }
              ]
            },
            {
              width: '40%',
              stack: [
                {
                  text: produto.VENDA.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  }),
                  fontSize: 18,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 10, 0, 0]
                },
                {
                  text: produto.UNIDADE,
                  fontSize: 10,
                  alignment: 'center',
                  margin: [0, 2, 0, 0]
                }
              ]
            }
          ]
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  render() {
    return (
      <div>
        <button onClick={this.generatePDF} className="dropdown-item">
          <i className="fas fa-barcode etiquetaIcon"></i> Gerar Etiqueta
        </button>
      </div>
    );
  }
}

export default EtiquetaPDF;

